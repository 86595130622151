import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog as MuiDialog,
} from "@material-ui/core";
import {
  Box,
  Button,
  Field,
  Form,
  Modal,
  Select,
  Typography,
} from "components";
import { compose, withFormik, withHooks, withTranslation } from "enhancers";
import { get } from "lodash";
import enrollmentStore from "stores/enrollmentStore";
import styled from "styled-components";
import { AppColor } from "theme/app-color";
import { gql, paths } from "utils/helper";
import { handleNavigateEnrollmentStep } from "./enrollmentRoutes";

const Dialog = styled(MuiDialog)`
  .MuiDialog-paperScrollPaper {
    margin: 1vw;
    min-width: 280px;
    overflow: hidden;
  }
`;

const CreateEnrollmentModal = (props: any) => (
  <Dialog open={props.visible} fullWidth>
    <Form>
      <DialogTitle>
        <Typography variant="h4">{props.t(".title")}</Typography>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column">
          <Typography variant="body1" color={AppColor["Text/Dark Grey"]}>
            {props.t(".description")}
          </Typography>
          <Box mt={6} mb={6}>
            <Field
              component={Select}
              name="year"
              type="text"
              label={props.t(".year")}
              options={props.yearOptions}
              fast
              required
              fullWidth
              disableClearable={true}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box>
          <Button
            onClick={props.handleClose}
            style={{ border: "none" }}
            mr="16px"
          >
            {props.t(".cancel")}
          </Button>
          <Button
            type="submit"
            style={{ color: AppColor["Other/Info"], border: "none" }}
          >
            {props.t(".title")}
          </Button>
        </Box>
      </DialogActions>
    </Form>
  </Dialog>
);

export const API = {
  CREATE_ENROLLMENT: gql`
    mutation CREATE_ENROLLMENT($year: Float!) {
      createEnrollment(year: $year) {
        id
        subState
        year
      }
    }
  `,
};

const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({
      year: null,
    }),
  }),
  withTranslation({ prefix: "pages.main.enrollment.CreateEnrollmentModal" }),
  withHooks((props: any, hooks: any) => {
    const { useMemo, useCallback, useMutation, useHandleSubmit } = hooks;
    const { visible, onCancel, values, t } = props;
    const [createInsurance] = useMutation(API.CREATE_ENROLLMENT, {
      onCompleted: (data: any) => {
        const { id, subState } = data.createEnrollment;
        handleNavigateEnrollmentStep(id, subState);
      },
      onError: (error: any) => {
        if (error.networkError.statusCode === 457) {
          const errorMessage = t(
            get(
              error,
              "networkError.result.errors[0].message",
              "เกิดข้อผิดพลาดบางอย่าง โปรดติดต่อทีมงาน"
            ),
            { year: values.year }
          );
          Modal.alert({
            title: t(".error.title"),
            children: errorMessage,
            okButtonLabel: t(".error.close"),
            onOk: async ({ close }: any) => {
              close();
            },
            okButtonColor: AppColor["Other/Info"],
            disableBackdropClick: true,
          });
        }
      },
    });

    const handleClose = useCallback(() => {
      onCancel();
    }, [onCancel]);

    useHandleSubmit(
      async (values: any) => {
        await createInsurance({
          variables: { year: values.year },
        });
      },
      [createInsurance]
    );

    const yearOptions = useMemo(() => {
      const currentYear = new Date().getFullYear();
      return [
        { value: currentYear - 5, label: (currentYear - 5).toString() },
        { value: currentYear - 4, label: (currentYear - 4).toString() },
        { value: currentYear - 3, label: (currentYear - 3).toString() },
        { value: currentYear - 2, label: (currentYear - 2).toString() },
        { value: currentYear - 1, label: (currentYear - 1).toString() },
        { value: currentYear, label: currentYear.toString() },
        { value: currentYear + 1, label: (currentYear + 1).toString() },
      ];
    }, []);

    return { yearOptions, visible, handleClose };
  })
);

export default enhancer(CreateEnrollmentModal);
