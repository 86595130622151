import { compose, withHooks, withTranslation, withStores } from "enhancers";
import { PageContent } from "layouts";
import { Box, Typography, Button } from "components";
import { gql, homePath, paths } from "utils/helper";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import insuranceStore from "stores/insuranceStore";
import { formatDate } from "utils/helper";
import styled from "styled-components";
import { insuranceStepPath } from "../insuranceRoutes";

const useStyles = makeStyles((theme) => ({
  primaryButton: {
    backgroundColor: "#376FD0",
    borderColor: "#376FD0",
    color: "white",
    "&:hover": {
      backgroundColor: "#376FD0",
      borderColor: "#376FD0",
      color: "white",
    },
  },
  secondaryButton: {
    backgroundColor: "white",
    borderColor: "#376FD0",
    color: "#376FD0",
    "&:hover": {
      backgroundColor: "white",
      borderColor: "#376FD0",
      color: "#376FD0",
    },
  },
}));

const CustomHtmlContainer = styled(Box)`
  .CustomHtml > div > p {
    margin: 0;
  }
`;

export const ViewInsurancePage = (props: any) => (
  <PageContent title={props.title} breadcrumbs={props.breadcrumbs}>
    <Box display="flex">
      <Box display="flex" flexDirection="column" minWidth={328}>
        <Typography variant="h6" mb={2}>
          {props.t(".insuranceName")}
        </Typography>

        <Typography variant="h6" mb={2}>
          {props.t(".insuranceType")}
        </Typography>

        <Typography variant="h6" mb={2}>
          {props.t(".startDate")}
        </Typography>

        <Typography variant="h6" mb={2}>
          {props.t(".endDate")}
        </Typography>

        <Typography variant="h6" mb={2}>
          {props.t(".premium")}
        </Typography>

        <Typography variant="h6" mb={2}>
          {props.t(".remark")}
        </Typography>

        <Typography variant="h6" mb={2}>
          {props.t(".description")}
        </Typography>
      </Box>

      <Box display="flex" flexDirection="column">
        <Typography variant="body1" mb={2}>
          {props.insuranceDetail?.nameTh || "-"}
        </Typography>

        <Typography variant="body1" mb={2}>
          {props.insuranceDetail?.insuranceType || "-"}
        </Typography>

        <Typography variant="body1" mb={2}>
          {props.insuranceDetail?.protectionStartDate || "-"}
        </Typography>

        <Typography variant="body1" mb={2}>
          {props.insuranceDetail?.protectionEndDate || "-"}
        </Typography>

        <Typography variant="body1" mb={2}>
          {props.insuranceDetail?.premium || "-"}
        </Typography>

        <Typography variant="body1" mb={2}>
          {props.insuranceDetail?.remarkTh || "-"}
        </Typography>

        <CustomHtmlContainer mb={2}>
          <Typography className="CustomHtml" variant="body1" mb={2}>
            {props.insuranceDetail?.description ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: props.insuranceDetail?.description,
                }}
              />
            ) : (
              "-"
            )}
          </Typography>
        </CustomHtmlContainer>
      </Box>
    </Box>
    {props.viewState !== "enrollment" && (
      <Button
        width={74}
        p={0}
        mt={10}
        className={props.classes.secondaryButton}
        onClick={props.onBack}
      >
        {props.t(".back")}
      </Button>
    )}
  </PageContent>
);

export const API = {
  FETCH_INSURANCE_TYPES: gql`
    query FETCH_INSURANCE_TYPES {
      insuranceTypes {
        id
        symbol
        nameTh
        nameEn
      }
    }
  `,
  FETCH_INSURANCE_DETAIL: gql`
    query FETCH_INSURANCE_DETAIL($id: String!) {
      masterInsuranceDetail(id: $id) {
        id
        year
        state
        companyName
        protectionStartDate
        protectionEndDate
        insurances {
          id
          nameTh
          nameEn
          premium
          remarkTh
          remarkEn
          description
          insuranceTypeId
        }
        insurancePackages {
          id
          nameTh
          nameEn
          remarkTh
          remarkEn
          insurancesInsurancePackages {
            id
            insurancePlanId
            packageId
          }
        }
      }
    }
  `,
};

const enhancer = compose(
  withStores((stores: any) => ({
    packages: stores.insuranceStore.packages,
    insurances: stores.insuranceStore.insurances,
  })),
  withTranslation({ prefix: "pages.main.insurance.InsurancePackage" }),
  withHooks((props: any, hooks: any) => {
    const {
      useParams,
      useMemo,
      useQuery,
      useLocation,
      useDataTranslation,
    } = hooks;
    const { insurances, t } = props;
    const classes = useStyles();
    const { insuranceId, id } = useParams();

    const { search } = useLocation();
    const viewState = useMemo(
      () => new URLSearchParams(search).get("viewState"),
      [search]
    );

    const packageIndex = useMemo(
      () => new URLSearchParams(search).get("packageIndex"),
      [search]
    );

    const { data: types, loading: insuranceTypeLoading } = useQuery(
      API.FETCH_INSURANCE_TYPES
    );
    const insuranceTypesTranslated = useDataTranslation(types);
    const { data: detail, loading: detailLoading } = useQuery(
      API.FETCH_INSURANCE_DETAIL,
      {
        variables: { id: insuranceId },
        onCompleted: (data: any) => {
          const response = data.masterInsuranceDetail;
          insuranceStore.initialMasterInsurance(response);
        },
        fetchPolicy: "network-only",
      }
    );

    const fromViewState = useMemo(() => {
      if (viewState === "new") {
        return {
          path: paths.insurancePackageNewPath(insuranceId),
          label: "เพิ่ม Package",
        };
      }

      if (viewState === "editing") {
        return {
          path: paths.insurancePackageEditPath(insuranceId, packageIndex),
          label: "แก้ไข Package",
        };
      }

      if (viewState === "summary") {
        return {
          path: paths.insuranceDetailPath(insuranceId),
          label: "ตรวจสอบและเผยแพร่",
        };
      }

      if (viewState === "enrollment") {
        return {
          path: paths.enrollmentDetailPath(insuranceId),
          label: "enrollment",
        };
      }

      return {
        path: paths.insurancePackageNewPath(insuranceId),
        label: "เพิ่ม Package",
      };
    }, [insuranceId, packageIndex, viewState]);

    const onBack = useCallback(() => fromViewState.path.push(), [
      fromViewState,
    ]);

    const insuranceDetail = useMemo(() => {
      if (insurances && detail && insuranceTypesTranslated) {
        const list = Object.keys(insurances).map((key) => insurances[key]);
        const found = list.find((ins: any) => ins.id === id);
        const insuranceType = insuranceTypesTranslated.insuranceTypes.find(
          (type: any) => type.id === found?.insuranceTypeId
        )?.name;

        const protectionStartDate = formatDate(
          detail.masterInsuranceDetail.protectionStartDate,
          "dd/MM/yyy"
        );
        const protectionEndDate = formatDate(
          detail.masterInsuranceDetail.protectionEndDate,
          "dd/MM/yyy"
        );

        return {
          ...found,
          insuranceType,
          protectionStartDate: protectionStartDate,
          protectionEndDate: protectionEndDate,
        };
      }
      return;
    }, [insurances, detail, id, insuranceTypesTranslated]);

    const isLoading = useMemo(() => {
      return detailLoading || insuranceTypeLoading;
    }, [detailLoading, insuranceTypeLoading]);

    const config = useMemo(() => {
      if (isLoading) return;
      const year = detail?.masterInsuranceDetail.year;
      return {
        year,
      };
    }, [isLoading, detail]);

    const title = useMemo(() => insuranceDetail?.nameTh, [insuranceDetail]);

    const breadcrumbs = useMemo(() => {
      if (viewState === "enrollment") {
        return [
          {
            path: homePath(),
            label: t(".breadcrumbs.home"),
          },
          { path: null, label: title },
        ];
      } else {
        return [
          {
            path: homePath(),
            label: t(".breadcrumbs.home"),
          },
          {
            path: paths.insurancePath(),
            label: t(".breadcrumbs.insurance"),
          },
          {
            path: insuranceStepPath.insurancePackage(insuranceId).toString(),
            label: t(".breadcrumbs.insuranceDetail", {
              year: config?.year,
            }),
          },
          {
            path: fromViewState.path,
            label: fromViewState.label,
          },

          { path: null, label: title },
        ];
      }
    }, [t, insuranceId, config, title, fromViewState, viewState]);

    return { classes, onBack, insuranceDetail, breadcrumbs, title, viewState };
  })
);

export default enhancer(ViewInsurancePage);
