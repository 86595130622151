import React from "react";
import Box from "@material-ui/core/Box";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

import { compose, defaultProps, withHooks } from "enhancers";

const Editor = ({ layoutProps, editorProps }: any) => (
  <Box {...layoutProps}>
    <ReactQuill ref={editorProps.outerRef} {...editorProps} />
  </Box>
);

const enhancer = compose(
  defaultProps({
    theme: "snow",
  }),
  withHooks((props: any, hooks: any) => {
    const { form, field, theme, placeholder, outerRef, ...rest } = props;
    const { useMemo, useCallback } = hooks;

    const { setFieldValue } = form;
    const { name, value } = field;
    const layoutProps = rest;

    const onChange = useCallback(
      (value: any) => {
        if (value) {
          setFieldValue(name, value);
        }
      },
      [setFieldValue, name]
    );

    const editorProps = useMemo(() => {
      return {
        theme,
        placeholder,
        onChange,
        value: value ?? "",
        outerRef,
      };
    }, [theme, placeholder, onChange, value, outerRef]);

    return {
      layoutProps,
      editorProps,
    };
  })
);

export default enhancer(Editor);
