import { Redirect, Route, Switch } from "components";
import { history } from "components/common/BrowserRouter";
import { EnumEnrollmentSubState } from "constants/enums/enrollment-sub-state";
import { compose, withHooks, withStores } from "enhancers";
import { last } from "lodash";
import qs from "qs";
import { EnrollmentAttendee } from "./EnrollmentAttendee";
import { EnrollmentInsurancePackage } from "./InsurancePackage";
import { InsurancePlanValidating } from "./InsurancePlanValidating";
import { PeriodSetting } from "./PeriodSetting";
import AttendeeGrouping from "./grouping/index";
import PublishEnrollment from "./validate_and_published/index";

class Path extends String {
  newTab = () => {
    window.open(this.toString(), "__blank");
  };

  public push = (extendedPath: String = "") => {
    if (history) {
      history.push(`${this.toString()}${extendedPath ?? ""}`);
    }
  };

  reload = () => {
    window.location.href = this.toString();
  };

  makePath = (path: Function): ((...params: any[]) => Path) => {
    return (...args: any[]) => {
      let pathMethod = typeof path === "string" ? () => path : path;
      let absolutePath = null;

      if (last(args) instanceof Object) {
        const params = args.slice(0, args.length - 1);

        const query = last(args);
        const queryString = qs.stringify(query);

        absolutePath = `${this}/${pathMethod(...params)}?${queryString}`;
      } else {
        absolutePath = `${this}/${pathMethod(...args)}`;
      }

      return new Path(absolutePath.replace(/\/+/, "/"));
    };
  };

  periodEditing = this.makePath(
    (id: string) => `enrollment/${id}/${EnumEnrollmentSubState.period_editing}`
  );
  attendeeValidating = this.makePath(
    (id: string) =>
      `enrollment/${id}/${EnumEnrollmentSubState.attendees_validating}`
  );
  insurancePlanValidating = this.makePath(
    (id: string) =>
      `enrollment/${id}/${EnumEnrollmentSubState.insurance_plans_validating}`
  );
  insurancePackageValidating = this.makePath(
    (id: string) =>
      `enrollment/${id}/${EnumEnrollmentSubState.insurance_packages_validating}`
  );
  attendeeGroupValidating = this.makePath(
    (id: string) =>
      `enrollment/${id}/${EnumEnrollmentSubState.attendee_groups_editing}`
  );
  validating = this.makePath(
    (id: string) => `enrollment/${id}/${EnumEnrollmentSubState.validating}`
  );
}

export const enrollmentPaths = new Path();

const StepperPage = ({ id }: any) => {
  return (
    <Switch>
      <Route
        path={enrollmentPaths.periodEditing(":id")}
        component={PeriodSetting}
      />
      <Route
        path={enrollmentPaths.attendeeValidating(":id")}
        component={EnrollmentAttendee}
      />
      <Route
        path={enrollmentPaths.insurancePlanValidating(":id")}
        component={InsurancePlanValidating}
      />
      <Route
        path={enrollmentPaths.insurancePackageValidating(":id")}
        component={EnrollmentInsurancePackage}
      />
      <Route
        path={enrollmentPaths.attendeeGroupValidating(":id")}
        component={AttendeeGrouping}
      />
      <Route
        path={enrollmentPaths.validating(":id")}
        component={PublishEnrollment}
      />
      <Redirect to={enrollmentPaths.periodEditing(id).toString()} />
    </Switch>
  );
};

interface RoutesProps {
  id: string;
}

const Routes = (props: RoutesProps) => <StepperPage {...props} />;

const enhancer = compose(
  withStores((stores: { appStore: { currentUser: any } }) => ({
    currentUser: stores.appStore.currentUser,
  })),
  withHooks((props: any, hooks: any) => {
    const { currentUser } = props;
    const { useParams } = hooks;

    const { id } = useParams();

    return { id };
  })
);

export const handleNavigateEnrollmentStep = (
  id: string,
  state: EnumEnrollmentSubState
) => {
  switch (state) {
    case EnumEnrollmentSubState.period_editing:
      enrollmentPaths.periodEditing(id).push();
      break;
    case EnumEnrollmentSubState.attendees_validating:
      enrollmentPaths.attendeeValidating(id).push();
      break;
    case EnumEnrollmentSubState.insurance_plans_validating:
      enrollmentPaths.insurancePlanValidating(id).push();
      break;
    case EnumEnrollmentSubState.insurance_packages_validating:
      enrollmentPaths.insurancePackageValidating(id).push();
      break;
    case EnumEnrollmentSubState.attendee_groups_editing:
      enrollmentPaths.attendeeGroupValidating(id).push();
      break;
    case EnumEnrollmentSubState.validating:
    case EnumEnrollmentSubState.completed:
      enrollmentPaths.validating(id).push();
      break;
    default:
      break;
  }
};

export const EnrollmentRoutes = enhancer(Routes);
