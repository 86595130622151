import { Grid, Field, TextField, Select, Hidden } from "components";
import {
  subDistrictOptions,
  districtOptions,
  provinceOptions,
  zipCodeOptions,
} from "./helper";

const DeliveryOnModal = (props: any) => (
  <>
    <Hidden when={props.taxPayerType !== "personal"}>
      <Grid item xs={6}>
        <Field
          component={TextField}
          name="firstName"
          type="text"
          label="ชื่อ"
          fullWidth
          disabled={props.disabled}
        />
      </Grid>
      <Grid item xs={6} pr="8px !important">
        <Field
          component={TextField}
          name="lastName"
          type="text"
          label="นามสกุล"
          fullWidth
          disabled={props.disabled}
        />
      </Grid>
    </Hidden>
    <Hidden when={props.taxPayerType !== "corporation"}>
      <Grid item xs={6}>
        <Field
          component={TextField}
          name="companyName"
          type="text"
          label={props.companyNameLabel ?? "ชื่อนิติบุคคล"}
          fullWidth
          disabled={props.disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <Hidden when={props.noBranch}>
          <Field
            component={TextField}
            name="branchName"
            type="text"
            label="สำนักงาน/สาขา"
            fullWidth
            disabled={props.disabled}
          />
        </Hidden>
      </Grid>
    </Hidden>
    <Hidden when={!props.withTaxInfo}>
      <Grid item xs={6}>
        <Field
          component={TextField.TaxId}
          name="taxId"
          label="เลขประจำตัวผู้เสียภาษี"
          fullWidth
          disabled={props.disabled}
        />
      </Grid>
    </Hidden>
    <Grid item xs={12} pr="8px !important">
      <Field
        component={TextField}
        name="address"
        type="text"
        label="ที่อยู่"
        fullWidth
        disabled={props.disabled}
      />
    </Grid>
    <Grid item xs={6}>
      <Field
        component={Select}
        name="subDistrict"
        type="text"
        label="แขวง/ตำบล"
        options={subDistrictOptions}
        transformDisplay={props.transformDisplaySubDistrict}
        freeSolo
        fullWidth
        fuse
        disabled={props.disabled}
      />
    </Grid>
    <Grid item xs={6} pr="8px !important">
      <Field
        component={Select}
        name="district"
        type="text"
        label="เขต/อำเภอ"
        options={districtOptions}
        freeSolo
        fullWidth
        disabled={props.disabled}
      />
    </Grid>
    <Grid item xs={6}>
      <Field
        component={Select}
        name="province"
        type="text"
        label="จังหวัด"
        options={provinceOptions}
        fullWidth
        disabled={props.disabled}
      />
    </Grid>
    <Grid item xs={6} pr="8px !important">
      <Field
        component={Select}
        name="zipCode"
        type="text"
        label="รหัสไปรษณีย์"
        options={zipCodeOptions}
        freeSolo
        fullWidth
        disabled={props.disabled}
      />
    </Grid>
    <Grid item xs={12} pr="8px !important">
      <Field
        component={TextField}
        name="remark"
        type="text"
        label="หมายเหตุ"
        fullWidth
        disabled={props.disabled}
      />
    </Grid>
    <Grid item xs={6}>
      <Field
        component={TextField.PhoneNumber}
        name="phoneNumber"
        label="เบอร์โทรศัพท์"
        fullWidth
        disabled={props.disabled}
      />
    </Grid>
  </>
);

export default DeliveryOnModal;
