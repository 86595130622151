import { compose, withHooks, withProps } from "enhancers";
import { FieldArray } from "formik";
import { isEqual } from "lodash";

const enhancer = compose(
  withHooks((props: any, hooks: any) => {
    const { name, component, ...rest } = props;
    const { usePrevious, useMemo } = hooks;

    const prevRest = usePrevious(rest);

    const isRestChanged = !isEqual(prevRest, rest);
    const customComponent = useMemo(() => {
      return withProps(() => rest)(component);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRestChanged]);

    return {
      component: customComponent,
      name,
      ...rest,
    };
  })
);

export default enhancer(FieldArray);
