import {
  EnrollmentStateKey,
  EnumEnrollmentSubState,
} from "constants/enums/enrollment-sub-state";
import { BaseStore } from "./index";
import { find, omit } from "lodash";

class EnrollmentStore extends BaseStore {
  constructor() {
    super("enrollmentStore", {
      year: "",
      lastSyncEmployee: null,
      masterInsuranceId: "",
      attendeeGroups: [],
      insurances: null,
      enrollmentState: null,
    });
  }

  setLastSyncEmployee(date: string) {
    localStorage.setItem("LAST_SYNC_EMPLOYEES", date);
    this.setState({
      ...this.state,
      lastSyncEmployee: date,
    });
  }

  getLocalStorageEnrollmentSubState() {
    return localStorage.getItem(EnrollmentStateKey);
  }

  clearStore() {
    this.setState({
      lastSyncEmployee: null,
      year: "",
      masterInsuranceId: "",
      attendeeGroups: [],
      insurances: null,
      enrollmentState: null,
    });
    localStorage.removeItem(EnrollmentStateKey);
  }

  updateAttendee(data: any) {
    const attendeeGroups = [...this.state["attendeeGroups"]];

    const resultAttendeeGroups = attendeeGroups.map((group: any) => {
      const matchingGroup = data.find(
        (groupData: any) => group.id === groupData.id
      );

      return matchingGroup
        ? { ...group, ...omit(matchingGroup, "masterBenefitInsurances") }
        : group;
    });

    this.setState({
      attendeeGroups: resultAttendeeGroups,
    });
  }

  saveInsuranceAttendeeGroupingTemp(attendeeGroupId: string, data: any[]) {
    const attendeeGroups = [...this.state["attendeeGroups"]];

    let currentAttendeeGroups = find(
      attendeeGroups,
      (group) => group.id === attendeeGroupId
    );

    const currentMasterBenefitInsurancePlans =
      currentAttendeeGroups?.masterBenefitInsurances?.[0]
        ?.masterBenefitInsurancePlans || [];

    let insuranceDataDic = {};
    currentMasterBenefitInsurancePlans.forEach((item: any) => {
      insuranceDataDic = {
        ...insuranceDataDic,
        [item.id]: item,
      };
    });

    let mergedInsuranceDataDic = { ...insuranceDataDic };

    data.forEach((item: any) => {
      mergedInsuranceDataDic = {
        ...mergedInsuranceDataDic,
        [item.id]: item,
      };
    });

    const newAttendeeGroups = attendeeGroups.map((group: any) => {
      if (group.id === attendeeGroupId) {
        const result = Object.keys(mergedInsuranceDataDic).map(
          (key) =>
            mergedInsuranceDataDic[key as keyof typeof mergedInsuranceDataDic]
        );
        const groupResult = [
          {
            ...group.masterBenefitInsurances[0],
            masterBenefitInsurancePlans: result,
            masterBenefitInsurancePackages: [],
          },
        ];

        return {
          ...group,
          masterBenefitInsurances: groupResult,
        };
      } else {
        return group;
      }
    });

    this.setState({
      attendeeGroups: newAttendeeGroups,
    });
  }

  removeInsuranceAttendeeGroupingTemp(attendeeGroupId: string, removeId: any) {
    const attendeeGroups = [...this.state["attendeeGroups"]];

    let currentAttendeeGroups = find(
      attendeeGroups,
      (group) => group.id === attendeeGroupId
    );

    const currentMasterBenefitInsurancePlans =
      currentAttendeeGroups?.masterBenefitInsurances?.[0]
        ?.masterBenefitInsurancePlans || [];

    let insuranceDataDic = {};
    currentMasterBenefitInsurancePlans.forEach((item: any) => {
      if (item.id !== removeId) {
        insuranceDataDic = {
          ...insuranceDataDic,
          [item.id]: item,
        };
      }
    });

    const newAttendeeGroups = attendeeGroups.map((group: any) => {
      if (group.id === attendeeGroupId) {
        const result = Object.keys(insuranceDataDic).map(
          (key) => insuranceDataDic[key as keyof typeof insuranceDataDic]
        );
        const groupResult = [
          {
            ...group.masterBenefitInsurances[0],
            masterBenefitInsurancePlans: result,
            masterBenefitInsurancePackages: [],
          },
        ];
        return {
          ...group,
          masterBenefitInsurances: groupResult,
        };
      } else {
        return group;
      }
    });

    this.setState({
      attendeeGroups: newAttendeeGroups,
    });
  }

  saveInsurancePackageAttendeeGroupingTemp(attendeeGroupId: string, data: any) {
    const attendeeGroups = [...this.state["attendeeGroups"]];

    let mergedInsuranceDataDic = {};

    data.forEach((item: any) => {
      mergedInsuranceDataDic = {
        ...mergedInsuranceDataDic,
        [item.id]: item,
      };
    });

    const newAttendeeGroups = attendeeGroups.map((group: any) => {
      if (group?.id === attendeeGroupId) {
        const result = Object.keys(mergedInsuranceDataDic).map(
          (key) =>
            mergedInsuranceDataDic[key as keyof typeof mergedInsuranceDataDic]
        );

        const groupResult = [
          {
            ...group.masterBenefitInsurances[0],
            masterBenefitInsurancePackages: result,
            masterBenefitInsurancePlans: [],
          },
        ];
        return {
          ...group,
          masterBenefitInsurances: groupResult,
        };
      } else {
        return group;
      }
    });

    this.setState({
      attendeeGroups: newAttendeeGroups,
    });
  }
}

const enrollmentStore = new EnrollmentStore();

export default enrollmentStore;
