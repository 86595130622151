import { GridColDef } from "@material-ui/data-grid";
import { Box, Modal, Table } from "components";

import { compose, withHooks, withStores, withTranslation } from "enhancers";
import { TFunction } from "i18next";
import { MAIN_MODAL_INITIAL_VALUES } from "stores/appStore";
import { v4 } from "uuid";

interface InsuranceSelectorProps {
  title: string;
  columns: GridColDef[];
  tableData: [];
  isShow: boolean;
  handleTempChange: (ids: any) => void;
  handleClickOk: (props: any) => void;
  onCancel: () => void;
  t: TFunction;
}

const InsuranceSelectorTableComponent = ({
  title,
  columns,
  tableData,
  isShow,
  handleTempChange,
  handleClickOk,
  onCancel,
  t,
}: InsuranceSelectorProps) => (
  <Modal
    {...MAIN_MODAL_INITIAL_VALUES}
    isShow={isShow}
    title={title}
    onOk={handleClickOk}
    onCancel={onCancel}
  >
    <Box mt={4}>
      <Table
        columns={columns}
        rows={tableData}
        density="compact"
        autoHeight
        rowsPerPageOptions={[5, 10]}
        style={{ minHeight: "40px" }}
        hideFooterPagination
        hideFooterRowCount
        disableSelectionOnClick
        displaySelectAll={false}
        checkboxSelection={true}
        hideFooterSelectedRowCount
        hideSelectAll
        onSelectionModelChange={handleTempChange}
      />
    </Box>
  </Modal>
);

const enhancer = compose(
  withStores((stores: any) => {}),
  withTranslation({
    prefix: "pages.main.enrollment.attendeeGrouping.InsuranceList",
  }),
  withHooks((props: any, hooks: any) => {
    const { useState, useMemo, useCallback } = hooks;
    const {
      label,
      masterInsurancePlans,
      packages,
      isShow,
      onSelectionChange,
      onCancel,
      t,
    } = props;

    const [tempItemsSelected, setTempItemsSelected] = useState([]);

    const modalTitle = useMemo(
      () =>
        masterInsurancePlans
          ? t(".modalInsuranceTitle", { type: label })
          : t(".packageHeader", { type: label }),
      [masterInsurancePlans, t, label]
    );

    const filteredData = useMemo(() => {
      if (masterInsurancePlans) {
        return masterInsurancePlans;
      } else if (packages) {
        return packages;
      } else {
        return [];
      }
    }, [masterInsurancePlans, packages]);

    const columns: GridColDef[] = useMemo(
      (): GridColDef[] => [
        {
          width: 250,
          field: "name",
          headerName: packages ? t(".packageName") : t(".insuranceName"),
        },
        {
          width: 250,
          field: "remark",
          headerName: t(".remark") || "",
        },
      ],
      [t, packages]
    );

    const handleTempChange = useCallback(
      (ids: any) => {
        setTempItemsSelected(ids);
      },
      [setTempItemsSelected]
    );

    const handleClickOk = useCallback(() => {
      let results = [];
      if (masterInsurancePlans) {
        results = masterInsurancePlans
          .filter((plans: any) => tempItemsSelected.includes(plans.id))
          .map((item: any) => ({
            id: v4(),
            isDefault: false,
            masterInsurancePlan: item,
          }));
      } else if (packages) {
        results = packages
          .filter((plans: any) => tempItemsSelected.includes(plans.id))
          .map((item: any) => ({
            id: v4(),
            isDefault: false,
            masterInsurancePackage: item,
          }));
      }
      onSelectionChange(results);
    }, [onSelectionChange, tempItemsSelected, masterInsurancePlans, packages]);

    return {
      title: modalTitle,
      tableData: filteredData,
      isShow,
      columns,
      handleTempChange,
      handleClickOk,
      onCancel,
      t,
    };
  })
);

export const InsuranceSelectorTable = enhancer(InsuranceSelectorTableComponent);
