import { Redirect, Route, Switch } from "components";
import { history } from "components/common/BrowserRouter";
import { EnumMasterInsuranceSubState } from "constants/enums/master-insurance-sub-state";
import { compose, withHooks, withStores } from "enhancers";
import { last } from "lodash";
import qs from "qs";
import { InsuranceDetail } from "./InsuranceDetail";
import insurancePackages from "./insurancePackages";
import InsurancePackagePreviewPage from "./preview";

class Path extends String {
  newTab = () => {
    window.open(this.toString(), "__blank");
  };

  public push = (extendedPath: String = "") => {
    if (history) {
      history.push(`${this.toString()}${extendedPath ?? ""}`);
    }
  };

  reload = () => {
    window.location.href = this.toString();
  };

  makePath = (path: Function): ((...params: any[]) => Path) => {
    return (...args: any[]) => {
      let pathMethod = typeof path === "string" ? () => path : path;
      let absolutePath = null;

      if (last(args) instanceof Object) {
        const params = args.slice(0, args.length - 1);

        const query = last(args);
        const queryString = qs.stringify(query);

        absolutePath = `${this}/${pathMethod(...params)}?${queryString}`;
      } else {
        absolutePath = `${this}/${pathMethod(...args)}`;
      }

      return new Path(absolutePath.replace(/\/+/, "/"));
    };
  };

  insuranceDetail = this.makePath(
    (id: string) =>
      `insurance/${id}/${EnumMasterInsuranceSubState.insurance_creating}`
  );
  insurancePackage = this.makePath(
    (id: string) =>
      `insurance/${id}/${EnumMasterInsuranceSubState.insurance_package_creating}`
  );
  insuranceValidation = this.makePath(
    (id: string) => `insurance/${id}/${EnumMasterInsuranceSubState.validating}`
  );
}

export const insuranceStepPath = new Path();

const StepperPage = ({ id }: any) => {
  return (
    <Switch>
      <Route
        path={insuranceStepPath.insuranceDetail(":id")}
        component={InsuranceDetail}
      />
      <Route
        path={insuranceStepPath.insurancePackage(":id")}
        component={insurancePackages}
      />
      <Route
        path={insuranceStepPath.insuranceValidation(":id")}
        component={InsurancePackagePreviewPage}
      />
      <Redirect to={insuranceStepPath.insuranceDetail(id).toString()} />
    </Switch>
  );
};

interface RoutesProps {
  id: string;
}

const Routes = (props: RoutesProps) => <StepperPage {...props} />;

const enhancer = compose(
  withStores((stores: { appStore: { currentUser: any } }) => ({
    currentUser: stores.appStore.currentUser,
  })),
  withHooks((props: any, hooks: any) => {
    const { currentUser } = props;
    const { useParams } = hooks;

    const { id } = useParams();

    return { id };
  })
);

export const handleNavigateInsuranceStep = (
  id: string,
  state: EnumMasterInsuranceSubState
) => {
  switch (state) {
    case EnumMasterInsuranceSubState.insurance_creating:
      insuranceStepPath.insuranceDetail(id).push();
      break;
    case EnumMasterInsuranceSubState.insurance_package_creating:
      insuranceStepPath.insurancePackage(id).push();
      break;
    case EnumMasterInsuranceSubState.validating:
    case EnumMasterInsuranceSubState.completed:
      insuranceStepPath.insuranceValidation(id).push();
      break;

    default:
      break;
  }
};

export const InsuranceRoute = enhancer(Routes);
