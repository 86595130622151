import { Button, Grid, Typography } from "components";
import { compose, withHooks, withStores, withTranslation } from "enhancers";
import { TFunction } from "i18next";
import { PageContent } from "layouts";
import { BreadcrumbsProps } from "layouts/PageContent";
import { formatDate, gql, homePath, paths } from "utils/helper";

interface EnrollmentInsuranceProps {
  t: TFunction;
  title: string;
  breadcrumbs: BreadcrumbsProps[];
  displayData: { label: string; value: string }[];
  handleClickBack: () => void;
}

const EnrollmentInsuranceComponent = (props: EnrollmentInsuranceProps) => (
  <PageContent title={props.title} breadcrumbs={props.breadcrumbs}>
    {props.displayData.map((data) => (
      <Grid container style={{ height: "28px", margin: "8px 0px" }}>
        <Grid item xs={4}>
          <Typography variant="body2">{data.label}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{data.value}</Typography>
        </Grid>
      </Grid>
    ))}
    <Button
      style={{ marginTop: "40px" }}
      variant="outlined"
      onClick={props.handleClickBack}
    >
      {props.t(".back")}
    </Button>
  </PageContent>
);

const API = {
  GET_ENROLLMENT: gql`
    query GET_ENROLLMENT($id: String!) {
      enrollment(id: $id) {
        id
        year
      }
    }
  `,
  GET_INSURANCE_PLAN: gql`
    query GET_INSURANCE_PLAN($id: String!) {
      insurancePlan(id: $id) {
        nameTh
        nameEn
        premium
        remarkTh
        remarkEn
        description
        protectionStartDate
        protectionEndDate
        description
        insuranceType {
          nameTh
          nameEn
        }
      }
    }
  `,
};

const enhancer = compose(
  withStores((stores: any) => ({
    currentUser: stores.appStore.currentUser,
  })),
  withTranslation({ prefix: "pages.main.enrollment.enrollmentInsurance" }),
  withHooks((props: any, hooks: any) => {
    const {
      useMemo,
      useCallback,
      useParams,
      useQuery,
      useMutation,
      useDataTranslation,
    } = hooks;
    const { t } = props;
    const { id, insuranceId } = useParams();
    const { data: enrollmentRes } = useQuery(API.GET_ENROLLMENT, {
      variables: { id },
    });
    const { data: insurancePlanRes } = useQuery(API.GET_INSURANCE_PLAN, {
      variables: { id: insuranceId },
      fetchPolicy: "network-only",
    });

    const insuranceTranslated = useDataTranslation(insurancePlanRes);

    const enrollment = useMemo(() => enrollmentRes?.enrollment, [
      enrollmentRes,
    ]);

    const insurancePlan = useMemo(() => insuranceTranslated?.insurancePlan, [
      insuranceTranslated,
    ]);
    const breadcrumbs = useMemo(
      (): BreadcrumbsProps[] => [
        {
          path: homePath(),
          label: t(".home"),
        },
        {
          path: paths.enrollmentPath(),
          label: t(".enrollment"),
        },
        {
          path: paths.enrollmentDetailPath(id),
          label: `${t(".enrollment")} ${enrollment?.year}`,
        },
        {
          path: null,
          label: insurancePlan?.name,
        },
      ],
      [t, id, enrollment, insurancePlan]
    );

    const displayData = useMemo(() => {
      if (insurancePlan) {
        return [
          {
            label: t(".name"),
            value: insurancePlan.name,
          },
          {
            label: t(".insuranceType"),
            value: insurancePlan.insuranceType.name,
          },
          {
            label: t(".protectionStartDate"),
            value: formatDate(insurancePlan.protectionStartDate, "dd/MM/yyyy"),
          },
          {
            label: t(".protectionEndDate"),
            value: formatDate(insurancePlan.protectionEndDate, "dd/MM/yyyy"),
          },
          {
            label: t(".premium"),
            value: insurancePlan.premium,
          },
          {
            label: t(".remark"),
            value: insurancePlan.remark,
          },
          {
            label: t(".description"),
            value: insurancePlan.description,
          },
        ];
      }
      return [];
    }, [t, insurancePlan]);

    const handleClickBack = useCallback(() => {
      paths.enrollmentDetailPath(id).push();
    }, [id]);

    return {
      title: insurancePlan?.name,
      breadcrumbs,
      displayData,
      handleClickBack,
    };
  })
);

export const EnrollmentInsurancePage = enhancer(EnrollmentInsuranceComponent);
