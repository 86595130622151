import { GridColDef } from "@material-ui/data-grid";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Paper,
  Table,
  Typography,
} from "components";
import Authorize from "components/Authorize";
import { PERMISSIONS } from "constants/enums/permissions";
import { compose, withHooks, withTranslation } from "enhancers";
import { TFunction } from "i18next";
import styled from "styled-components";
import { AppColor } from "theme/app-color";
import { formatDate, gql, publishedAlert } from "utils/helper";
import ViewInsuranceButton from "../insurance/insurancePackages/ViewInsuranceButtonComponent";
import { handleNavigateEnrollmentStep } from "./enrollmentRoutes";
import { isEqual } from "lodash";
import { EnumEnrollmentState } from "constants/enums/enrollment-state";

const CustomAccordion = styled(Accordion)`
  border-bottom: 1px solid ${AppColor["Background/Dark Grey"]};
  border-radius: 0px;
  &.MuiAccordion-rounded {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`;

const FooterContainer = styled("div")`
  display: flex;
  align-items: end;
  justify-content: space-between;
  margin-top: 40px;
`;

interface InsurancePlanValidatingProps {
  t: TFunction;
  insuranceTypes: { id: string; symbol: string; name: string }[];
  getInsurancePlansByType: (typeId: string) => any[];
  tableData: (typeId: string) => any[];
  columns: GridColDef[];
  handleClickNext: () => void;
  updatedAt: string;
  viewOnly: boolean;
}

const InsurancePlanValidatingComponent = (
  props: InsurancePlanValidatingProps
) => (
  <Paper px={4} py={6}>
    <Typography variant="h4">{props.t(".title")}</Typography>
    {props.insuranceTypes.map((type) => (
      <CustomAccordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="body2">{`${type.name}(${
            props.getInsurancePlansByType(type.id).length
          })`}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Table
            columns={props.columns}
            rows={props.tableData(type.id)}
            density="compact"
            style={{ minHeight: "40px" }}
            hideFooterPagination
            hideFooterRowCount
            disableSelectionOnClick
          />
        </AccordionDetails>
      </CustomAccordion>
    ))}
    <FooterContainer>
      <Box>
        {!props.viewOnly && (
          <Authorize permissions={[PERMISSIONS.ENROLLMENT_MANAGEMENT_EDIT]}>
            <Button
              style={{ marginTop: "40px" }}
              color="primary"
              onClick={props.handleClickNext}
            >
              {props.t(".next")}
            </Button>
          </Authorize>
        )}
      </Box>
      <Typography variant="body1">
        {props.t(".updatedAt", { date: props.updatedAt })}
      </Typography>
    </FooterContainer>
  </Paper>
);

const API = {
  FETCH_INSURANCE_PLANS: gql`
    query FETCH_INSURANCE_PLANS($id: String!) {
      insurancePlans(id: $id) {
        id
        nameTh
        nameEn
        remarkTh
        remarkEn
        premium
        insuranceTypeId
      }
    }
  `,
  FETCH_INSURANCE_TYPES: gql`
    query FETCH_INSURANCE_TYPES {
      insuranceTypes {
        id
        symbol
        nameTh
        nameEn
      }
    }
  `,
  CHANGE_TO_INSURANCE_PACKAGE: gql`
    mutation CHANGE_TO_INSURANCE_PACKAGE($id: String!) {
      changeToInsurancePackage(id: $id) {
        subState
      }
    }
  `,
  GET_ENROLLMENT: gql`
    query GET_ENROLLMENT($id: String!) {
      enrollment(id: $id) {
        id
        state
        masterInsurance {
          id
          updatedAt
        }
      }
    }
  `,
};

const enhancer = compose(
  withTranslation({ prefix: "pages.main.enrollment.InsurancePlanValidating" }),
  withHooks((props: any, hooks: any) => {
    const {
      useMemo,
      useCallback,
      useQuery,
      useParams,
      useMutation,
      useDataTranslation,
    } = hooks;
    const { t } = props;
    const { id } = useParams();

    const { data: insuranceTypesRes } = useQuery(API.FETCH_INSURANCE_TYPES);
    const { data: enrollmentRes } = useQuery(API.GET_ENROLLMENT, {
      variables: { id },
    });

    const { data: insurancePlansRes } = useQuery(API.FETCH_INSURANCE_PLANS, {
      variables: { id },
    });

    const enrollment = useMemo(() => enrollmentRes?.enrollment, [
      enrollmentRes,
    ]);

    const insurancePlanTranslated = useDataTranslation(insurancePlansRes);
    const insuranceTypesTranslated = useDataTranslation(insuranceTypesRes);

    const [changeToInsurancePackage] = useMutation(
      API.CHANGE_TO_INSURANCE_PACKAGE,
      {
        onCompleted: (data: any) => {
          const { subState } = data.changeToInsurancePackage;
          handleNavigateEnrollmentStep(id, subState);
        },
        skipSetError: true,
        onError: () => {
          publishedAlert();
        },
      }
    );

    const insuranceTypes = useMemo(
      () => insuranceTypesTranslated?.insuranceTypes || [],
      [insuranceTypesTranslated]
    );
    const insurancePlans = useMemo(
      () => insurancePlanTranslated?.insurancePlans || [],
      [insurancePlanTranslated]
    );

    const getInsurancePlansByType = useCallback(
      (typeId: string) => {
        const insurancePlansWithType = insurancePlans.filter(
          (insurance: any) => insurance.insuranceTypeId === typeId
        );
        return insurancePlansWithType;
      },
      [insurancePlans]
    );

    const tableData = useCallback(
      (typeId: string) => {
        return getInsurancePlansByType(typeId).map((data: any) => data) || [];
      },
      [getInsurancePlansByType]
    );

    const columns: GridColDef[] = useMemo(
      (): GridColDef[] => [
        {
          width: 200,
          field: "name",
          headerName: t(".name") || "",
        },
        {
          width: 350,
          field: "remark",
          headerName: t(".remark") || "",
        },
        {
          width: 100,
          field: "actions",
          headerName: " ",
          filterable: false,
          sortable: false,
          renderCell: (row: any) => (
            <ViewInsuranceButton insurancePlanId={row.id} />
          ),
        },
      ],
      [t]
    );

    const handleClickNext = useCallback(() => {
      changeToInsurancePackage({ variables: { id } });
    }, [changeToInsurancePackage, id]);

    const updatedAt = useMemo(() => {
      if (enrollment) {
        return formatDate(
          enrollment.masterInsurance.updatedAt,
          "dd/MM/yyyy, HH:mm"
        );
      }
      return "";
    }, [enrollment]);

    const viewOnly = useMemo(() => {
      let valid = true;
      valid =
        valid && isEqual(enrollment?.state, EnumEnrollmentState.published);
      valid = valid || isEqual(enrollment?.state, EnumEnrollmentState.active);
      valid = valid || isEqual(enrollment?.state, EnumEnrollmentState.closed);
      return valid;
    }, [enrollment]);

    return {
      insuranceTypes,
      getInsurancePlansByType,
      columns,
      tableData,
      handleClickNext,
      updatedAt,
      viewOnly,
    };
  })
);

export const InsurancePlanValidating = enhancer(
  InsurancePlanValidatingComponent
);
