import { CKEditor as OriginalCKEditor } from "ckeditor4-react";
import { compose, withHooks } from "enhancers";

const CKEditorComponent = ({ initData, handleChange, name }: any) => (
  <OriginalCKEditor
    initData={initData}
    name={name}
    editorUrl="https://cdn.ckeditor.com/4.22.1/full-all/ckeditor.js"
    onChange={(event) => {
      handleChange({
        target: { name, value: event.editor.getData() },
      });
    }}
  />
);

const enhancer = compose(
  withHooks((props: any, hook: any) => {
    const { useCallback, useMemo } = hook;
    const { form, field } = props;

    const handleChange = useCallback(
      (e: any) => {
        form.handleChange(e);
      },
      [form]
    );

    const name = useMemo(() => field.name, [field]);

    const initData = useMemo(() => form.values[name], [form, name]);

    return {
      initData,
      name,
      handleChange,
    };
  })
);

export const CKEditor = enhancer(CKEditorComponent);
