import React from "react";
import { compose, withHooks } from "enhancers";
import { Typography, Box, IconButton } from "components";
import { forceDownload, notifyError } from "utils/helper";
import { ReactComponent as DownloadIcon } from "assets/icon/download.svg";

const ImageDownloader = (props: any) => (
  // <Avatar
  //   {...props}
  //   variant="square"
  //   style={{ height: 120, width: 120 }}
  //   src={props.src}
  // />
  <Box
    flex={1}
    mb={5}
    display="flex"
    flexDirection="row"
    justifyContent="space-between"
  >
    <Typography variant="body1" color="#805D57">
      {props.value?.filename}
    </Typography>
    <Box display="flex" flexDirection="row">
      <Box>
        <IconButton
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            // e.stopPropagation();
            props.download();
          }}
        >
          <DownloadIcon />
        </IconButton>
      </Box>
    </Box>
  </Box>
);

const enhancer = compose(
  withHooks((props: any, hooks: any) => {
    const { useEffect, useState, useCallback } = hooks;
    const { value, ...rest } = props;

    const [image, setImage] = useState(null);

    const { url: imageUrl, filename: imageFilename } = value;

    useEffect(() => {
      if (imageFilename && imageUrl) {
        const loadFile = async () => {
          const blob = await fetch(imageUrl).then((r) => r.blob());
          const file = new File([blob], imageFilename, { type: blob.type });
          // form.setFieldValue(field.name, file);
          setImage(file);
        };
        loadFile();
      }
    }, [imageUrl, imageFilename]);

    const download = useCallback(() => {
      if (imageFilename && imageUrl) {
        const loadFile = async () => {
          try {
            const blob = await fetch(imageUrl).then((r) => r.blob());
            const file = new File([blob], imageFilename, {
              type: blob.type,
            });

            if (file) {
              const url = URL.createObjectURL(file);
              forceDownload(url, imageFilename);
            }
          } catch (e) {
            notifyError(e);
          }
        };

        loadFile();
      } else {
        notifyError("ไม่สามารถดาวน์โหลดไฟล์ได้");
      }
    }, [imageUrl, imageFilename]);

    const src = image instanceof File ? URL.createObjectURL(image) : null;

    return {
      ...rest,
      src,
      value,
      download,
    };
  })
);

export default enhancer(ImageDownloader);
