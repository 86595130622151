import { withTranslation } from "react-i18next";
import * as hooks from "hooks";
import { get } from "lodash";
import { replaceNameSpace } from "utils/helper";

const newWithTranslation = (options) => (mainProps) => {
  return withTranslation(options?.ns, { ...options })((props) => {
    const { useCallback, useRef } = hooks;
    const { t: tx } = props;
    const initPrefix = get(options, "prefix", "");
    const infos = useRef({ initPrefix });

    const t = useCallback(
      (ns, tOptions) => {
        const { initPrefix } = infos.current;
        const nameSpace = replaceNameSpace(initPrefix, ns);
        return tx(nameSpace, tOptions);
      },
      [tx]
    );

    return mainProps({ ...props, t });
  });
};

export default newWithTranslation;
