import { FormControl } from "@material-ui/core";
import { CheckboxWithLabel } from "formik-material-ui";
import Field from "./Field";
import Typography from "./Typography";
import { compose, withHooks, withTranslation } from "enhancers";
import { Box } from ".";

const Checkboxes = (props: any) => (
  <FormControl
    component="fieldset"
    style={{ display: "flex", padding: "24px" }}
  >
    <Box display="flex" mb={6}>
      <Field
        type="checkbox"
        component={CheckboxWithLabel}
        name={props.name}
        key={props.options[0]?.value}
        value={props.options[0]?.value}
        Label={{ label: props.t(".sync") }}
        fast={false}
        disabled={!props.hasEditPermission || props.isSuperAdminRole}
      />
    </Box>
    <Typography variant="h6">{props.t(".adminManage")}</Typography>
    <Box display="flex" mb={6}>
      <Box width="150px" mr={6}>
        <Field
          type="checkbox"
          component={CheckboxWithLabel}
          name={props.name}
          key={props.options[1]?.value}
          value={props.options[1]?.value}
          Label={{ label: props.t(".read") }}
          fast={false}
          disabled={
            !props.hasEditPermission ||
            props.isSuperAdminRole ||
            props.disabledAdminRead
          }
        />
      </Box>
      <Box width="150px">
        <Field
          type="checkbox"
          component={CheckboxWithLabel}
          name={props.name}
          key={props.options[2]?.value}
          value={props.options[2]?.value}
          Label={{ label: props.t(".edit") }}
          fast={false}
          disabled={!props.hasEditPermission || props.isSuperAdminRole}
        />
      </Box>
    </Box>
    <Typography variant="h6">{props.t(".permissionManage")}</Typography>
    <Box display="flex" mb={6}>
      <Box width="150px" mr={6}>
        <Field
          type="checkbox"
          component={CheckboxWithLabel}
          name={props.name}
          key={props.options[3]?.value}
          value={props.options[3]?.value}
          Label={{ label: props.t(".read") }}
          fast={false}
          disabled={
            !props.hasEditPermission ||
            props.isSuperAdminRole ||
            props.disabledPermissionRead
          }
        />
      </Box>
      <Box width="150px">
        <Field
          type="checkbox"
          component={CheckboxWithLabel}
          name={props.name}
          key={props.options[4]?.value}
          value={props.options[4]?.value}
          Label={{ label: props.t(".edit") }}
          fast={false}
          disabled={!props.hasEditPermission || props.isSuperAdminRole}
        />
      </Box>
    </Box>
    <Typography variant="h6">{props.t(".enrollmentManage")}</Typography>
    <Box display="flex" mb={6}>
      <Box width="150px" mr={6}>
        <Field
          type="checkbox"
          component={CheckboxWithLabel}
          name={props.name}
          key={props.options[5]?.value}
          value={props.options[5]?.value}
          Label={{ label: props.t(".read") }}
          fast={false}
          disabled={
            !props.hasEditPermission ||
            props.isSuperAdminRole ||
            props.disabledEnrollmentRead
          }
        />
      </Box>
      <Box width="150px">
        <Field
          type="checkbox"
          component={CheckboxWithLabel}
          name={props.name}
          key={props.options[6]?.value}
          value={props.options[6]?.value}
          Label={{ label: props.t(".edit") }}
          fast={false}
          disabled={!props.hasEditPermission || props.isSuperAdminRole}
        />
      </Box>
    </Box>
    <Typography variant="h6">{props.t(".reportManage")}</Typography>
    <Box display="flex" mb={6}>
      <Box width="150px" mr={6}>
        <Field
          type="checkbox"
          component={CheckboxWithLabel}
          name={props.name}
          key={props.options[7]?.value}
          value={props.options[7]?.value}
          Label={{ label: props.t(".read") }}
          fast={false}
          disabled={
            !props.hasEditPermission ||
            props.isSuperAdminRole ||
            props.disabledReportRead
          }
        />
      </Box>
      <Box width="150px">
        <Field
          type="checkbox"
          component={CheckboxWithLabel}
          name={props.name}
          key={props.options[8]?.value}
          value={props.options[8]?.value}
          Label={{ label: props.t(".edit") }}
          fast={false}
          disabled={!props.hasEditPermission || props.isSuperAdminRole}
        />
      </Box>
    </Box>

    <Typography variant="h6">{props.t(".insuranceManagement")}</Typography>
    <Box display="flex" mb={6}>
      <Box width="150px" mr={6}>
        <Field
          type="checkbox"
          component={CheckboxWithLabel}
          name={props.name}
          key={props.options[9]?.value}
          value={props.options[9]?.value}
          Label={{ label: props.t(".read") }}
          fast={false}
          disabled={
            !props.hasEditPermission ||
            props.isSuperAdminRole ||
            props.disabledInsuranceRead
          }
        />
      </Box>
      <Box width="150px">
        <Field
          type="checkbox"
          component={CheckboxWithLabel}
          name={props.name}
          key={props.options[10]?.value}
          value={props.options[10]?.value}
          Label={{ label: props.t(".edit") }}
          fast={false}
          disabled={!props.hasEditPermission || props.isSuperAdminRole}
        />
      </Box>
    </Box>

    <Typography variant="h6">{props.t(".settingManage")}</Typography>
    <Box display="flex">
      <Box width="150px" mr={6}>
        <Field
          type="checkbox"
          component={CheckboxWithLabel}
          name={props.name}
          key={props.options[11]?.value}
          value={props.options[11]?.value}
          Label={{ label: props.t(".read") }}
          fast={false}
          disabled={
            !props.hasEditPermission ||
            props.isSuperAdminRole ||
            props.disabledSettingRead
          }
        />
      </Box>
      <Box width="150px">
        <Field
          type="checkbox"
          component={CheckboxWithLabel}
          name={props.name}
          key={props.options[12]?.value}
          value={props.options[12]?.value}
          Label={{ label: props.t(".edit") }}
          fast={false}
          disabled={!props.hasEditPermission || props.isSuperAdminRole}
        />
      </Box>
    </Box>
  </FormControl>
);

const enhancer = compose(
  withTranslation({ prefix: "pages.main.roles.new" }),
  withHooks((props: any, hooks: any) => {
    const { useMemo } = hooks || {};
    const { options } = props || {};
    const permissionOptionList = useMemo(() => {
      const customOptions = options.map((option: any) => {
        return {
          translateKey: option.value,
          key: option.value,
          value: option.value,
        };
      });

      return customOptions;
    }, [options]);

    return { ...props, options };
  })
);

export default enhancer(Checkboxes);
