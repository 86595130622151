import { gql } from "@apollo/client";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Field,
  Select,
  TextField,
  Typography,
} from "components";
import Authorize from "components/Authorize";
import { MaterialIcon } from "components/common/MaterialIcon";
import { EnumMasterBenefitInsuranceType } from "constants/enums/master-benefit-insurance-type";
import { PERMISSIONS } from "constants/enums/permissions";
import { CrossStorageClient, CrossStorageHub } from "cross-storage";
import {
  compose,
  withAuthorize,
  withHooks,
  withStores,
  withTranslation,
} from "enhancers";
import env, { appEnv } from "env";
import { TFunction } from "i18next";
import { isEmpty, isEqual } from "lodash";
import enrollmentStore from "stores/enrollmentStore";
import styled from "styled-components";
import { AppColor } from "theme/app-color";
import { InsuranceList } from "./InsuranceList";

import { InsurancePackageList } from "./InsurancePackageList";
import { EnumEnrollmentState } from "constants/enums/enrollment-state";

const backofficeEnv = env["HOST"];

CrossStorageHub.init([
  {
    origin: new RegExp(backofficeEnv),
    allow: ["get", "set"],
  },
  {
    origin: new RegExp(appEnv.HOST),
    allow: ["get"],
  },
]);

const OutlinedButton = styled(Button)`
  height: 34px;
  min-width: 151px;
  border-color: ${AppColor["Other/Info"]} !important;
  color: ${AppColor["Other/Info"]};

  &.MuiButton-outlined.Mui-disabled {
    border-color: ${AppColor["Text/Light Grey"]} !important;
  }
`;

interface GroupPanelProps {
  attendeeGroups: [];
  expandedList: [];
  options: [];
  name: string;
  handleChange: Function;
  t: TFunction;
  insuranceTypes: any[];
  viewOnly: boolean;
  disablePreventLeaveDirtyForm: Function;
  getInsurancePlansByType: (attendeeGroup: any, typeId: string) => any[];
  values: any;
  hasEditPermission: boolean;
  handlePreview: any;
  masterInsurancePlans: any[];
}

const GroupPanel = (props: GroupPanelProps) => (
  <>
    {props.attendeeGroups?.map((attendeeGroup: any, index: number) => {
      const title = attendeeGroup.title;

      const selectedItem: any = props.expandedList.find(
        (item: any) => item.key === `${attendeeGroup.id}-${index}`
      );

      const expanded = selectedItem?.expanded ?? false;

      const attendeeGroupingType =
        props.values[`${props.name}`][`${index}`].type;

      return (
        <Accordion
          expanded={expanded}
          key={title}
          onChange={() => props.handleChange(`${attendeeGroup.id}-${index}`)}
        >
          <AccordionSummary
            expandIcon={
              <MaterialIcon
                name="ExpandMore"
                htmlColor={AppColor["Text/Dark Grey"]}
              />
            }
            style={{
              borderBottom: !expanded
                ? `1px solid ${AppColor["Background/Dark Grey"]}`
                : `0`,
            }}
          >
            <Typography variant="body1">{title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box mx={4} mt={6} width="100%">
              <Typography variant="caption" color={AppColor["Text/Light Grey"]}>
                {props.t(".info")}
              </Typography>

              <Box
                display="flex"
                flex={1}
                justifyContent="space-between"
                mt={4}
                mb={6}
                alignItems="center"
              >
                <Box display="flex">
                  <Box flex={1} mr={6} width={271}>
                    <Field
                      fast
                      label={props.t(".currentScore")}
                      fullWidth
                      component={TextField}
                      name={`${props.name}[${index}].point`}
                      type="number"
                      unit={props.t(".unit")}
                      disabled={!props.hasEditPermission || props.viewOnly}
                    />
                  </Box>
                  <Box flex={1} width={271}>
                    <Field
                      fast
                      label={props.t(".type")}
                      fullWidth
                      component={Select}
                      name={`${props.name}[${index}].type`}
                      options={props.options}
                      disabled={!props.hasEditPermission || props.viewOnly}
                    />
                  </Box>
                </Box>
                <Authorize
                  permissions={[PERMISSIONS.ENROLLMENT_MANAGEMENT_EDIT]}
                >
                  <OutlinedButton
                    variant="outlined"
                    ml={6}
                    disabled={
                      attendeeGroup.type === EnumMasterBenefitInsuranceType.none
                    }
                    onClick={() => props.handlePreview(attendeeGroup)}
                  >
                    <MaterialIcon
                      name="RemoveRedEyeOutlined"
                      style={{ marginRight: "10px" }}
                    />
                    {"Preview"}
                  </OutlinedButton>
                </Authorize>
              </Box>
              {attendeeGroupingType ===
                EnumMasterBenefitInsuranceType.custom && (
                <Box>
                  {props.insuranceTypes.map((type) => (
                    <InsuranceList
                      key={type.id}
                      label={type.name}
                      id={type.id}
                      symbol={type.symbol}
                      attendeeGroupId={attendeeGroup.id}
                      viewOnly={props.viewOnly}
                      masterInsurancePlans={props.masterInsurancePlans}
                      masterBenefitInsurancePlans={props.getInsurancePlansByType(
                        attendeeGroup,
                        type.id
                      )}
                    />
                  ))}
                </Box>
              )}
              {attendeeGroupingType ===
                EnumMasterBenefitInsuranceType.package && (
                <Box>
                  <InsurancePackageList
                    label="Package ประกันที่เลือกได้"
                    attendeeGroupId={attendeeGroup.id}
                    viewOnly={props.viewOnly}
                    masterInsurancePlans={props.masterInsurancePlans}
                    masterBenefitInsurancePackages={
                      attendeeGroup.masterBenefitInsurances?.[0]
                        ?.masterBenefitInsurancePackages
                    }
                  />
                </Box>
              )}
            </Box>
          </AccordionDetails>
        </Accordion>
      );
    })}
  </>
);

const API = {
  FETCH_INSURANCE_TYPES: gql`
    query FETCH_INSURANCE_TYPES {
      insuranceTypes {
        id
        symbol
        nameTh
        nameEn
      }
    }
  `,
  FETCH_INSURANCE_PLANS: gql`
    query FETCH_INSURANCE_PLANS($id: String!) {
      insurancePlans(id: $id) {
        id
        nameTh
        nameEn
        remarkTh
        remarkEn
        premium
        description
        insuranceTypeId
        insuranceType {
          id
          nameTh
          nameEn
        }
      }
    }
  `,
};

const enhancer = compose(
  withAuthorize(),
  withStores((stores: any) => ({
    year: stores.enrollmentStore.year,
    startDate: stores.enrollmentStore.startDate,
    endDate: stores.enrollmentStore.endDate,
    currentUser: stores.appStore.currentUser,
    attendeeGroups: stores.enrollmentStore.attendeeGroups,
    enrollmentState: stores.enrollmentStore.enrollmentState,
  })),
  withTranslation({ prefix: "pages.main.enrollment.attendeeGrouping" }),
  withHooks((props: any, hooks: any) => {
    const {
      useMemo,
      useCallback,
      useEffect,
      useState,
      useQuery,
      useDataTranslation,
      useParams,
    } = hooks || {};
    const {
      t,
      form,
      name,
      enrollmentState,
      hasPermission,
      year,
      startDate,
      endDate,
      currentUser,
      attendeeGroups: attendeeGroupsFromStore,
    } = props || {};

    const { values } = form || {};
    const { attendeeGroups } = values || {};
    const [expandedList, setExpandedList] = useState([]);
    const { id } = useParams();

    const viewOnly = useMemo(() => {
      let valid = true;

      valid = valid && isEqual(enrollmentState, EnumEnrollmentState.published);
      valid = valid || isEqual(enrollmentState, EnumEnrollmentState.active);
      valid = valid || isEqual(enrollmentState, EnumEnrollmentState.closed);
      return valid;
    }, [enrollmentState]);

    const { data: masterInsurancePlansResponse } = useQuery(
      API.FETCH_INSURANCE_PLANS,
      {
        variables: { id },
      }
    );
    const masterInsurancePlansTranslated = useDataTranslation(
      masterInsurancePlansResponse?.insurancePlans
    );
    const masterInsurancePlans = useMemo(() => {
      if (masterInsurancePlansTranslated) {
        return masterInsurancePlansTranslated;
      }
      return [];
    }, [masterInsurancePlansTranslated]);

    const { data: insuranceTypesResponse } = useQuery(
      API.FETCH_INSURANCE_TYPES
    );
    const insuranceTypesTranslated = useDataTranslation(
      insuranceTypesResponse?.insuranceTypes
    );
    const insuranceTypes = useMemo(() => {
      if (insuranceTypesTranslated) {
        return insuranceTypesTranslated;
      }
      return [];
    }, [insuranceTypesTranslated]);

    const handlePreview = useCallback(
      (attendeeGroupParam: any) => {
        const attendeeGroup = attendeeGroupsFromStore.find(
          (group: any) => group.id === attendeeGroupParam.id
        );

        const storageClient = new CrossStorageClient(`${backofficeEnv}`, {});
        const crossStorageData = {
          ...attendeeGroup,
          masterBenefitInsurances: [
            {
              ...attendeeGroup.masterBenefitInsurances[0],
              type: attendeeGroup.type,
            },
          ],
          year,
          startDate,
          endDate,
          insuranceType: insuranceTypes,
          firstName: currentUser.firstName,
          lastName: currentUser.lastName,
        };

        storageClient.onConnect().then(() => {
          storageClient.set(
            "CROSS_STORAGE_DATA",
            JSON.stringify(crossStorageData)
          );
        });
        window.open(`${appEnv.HOST}/preview/${attendeeGroup.id}`);
      },
      [
        insuranceTypes,
        year,
        startDate,
        endDate,
        currentUser,
        attendeeGroupsFromStore,
      ]
    );

    const hasEditPermission = useMemo(
      () => hasPermission([PERMISSIONS.ENROLLMENT_MANAGEMENT_EDIT]),
      [hasPermission]
    );

    const handleChange = useCallback(
      (key: string) => {
        const newExpandedList = expandedList.map((item: any) => {
          if (item.key === key) {
            return {
              ...item,
              expanded: !item.expanded,
            };
          } else {
            return item;
          }
        });

        setExpandedList(newExpandedList);
      },
      [expandedList]
    );

    const getInsurancePlansByType = useCallback(
      (attendeeGroup: any, typeId: string) => {
        const masterBenefitInsurancePlans =
          attendeeGroup?.masterBenefitInsurances?.[0]
            ?.masterBenefitInsurancePlans || [];

        const insurancePlansWithType = masterBenefitInsurancePlans.filter(
          (insurance: any) =>
            insurance.masterInsurancePlan.insuranceType.id === typeId
        );

        return insurancePlansWithType;
      },
      []
    );

    const options = useMemo(() => {
      return Object.entries(EnumMasterBenefitInsuranceType).map((data) => ({
        label: t(`.${data[0]}`),
        value: data[1],
      }));
    }, [t]);

    useEffect(() => {
      if (!isEmpty(attendeeGroups)) {
        const addedExpandedKey = attendeeGroups?.map(
          (group: any, index: number) => {
            return {
              key: `${group?.id}-${index}`,
              expanded: true,
            };
          }
        );
        setExpandedList(addedExpandedKey);
      }
    }, [attendeeGroups]);

    useEffect(() => {
      if (!isEmpty(values)) {
        enrollmentStore.updateAttendee(values.attendeeGroups);
      }
    }, [values]);

    return {
      attendeeGroups,
      expandedList,
      options,
      name,
      handleChange,
      insuranceTypes,
      values,
      viewOnly,
      getInsurancePlansByType,
      hasEditPermission,
      handlePreview,
      masterInsurancePlans,
    };
  })
);

export default enhancer(GroupPanel);
