import { compose, withHooks, withTranslation } from "enhancers";
import { Box, Typography, IconButton, Modal } from "components";
import { gql } from "utils/helper";
import { useCallback } from "react";
import { formatDate } from "utils/helper";
import styled from "styled-components";
import { MaterialIcon } from "components/common/MaterialIcon";
import { AppColor } from "theme/app-color";
import { isEmpty } from "lodash";
import { TFunction } from "i18next";

const CustomHtmlContainer = styled(Box)`
  .CustomHtml > div > p {
    margin: 0;
  }
`;

const Container = styled(Box)`
  display: flex;
  align-self: center;
  justify-content: center;

  .MuiIconButton-label > svg {
    width: 16px;
    height: 16px;
    color: ${AppColor["Text/Black"]};
  }
`;

interface ViewInsuranceButtonProps {
  openViewInsuranceModal: () => void;
  insurancePlanDetail: any;
}

interface ViewInsurancePageProps {
  t: TFunction;
  insurancePlanDetail: any;
}

const ViewInsuranceButton = (props: ViewInsuranceButtonProps) => (
  <Container>
    <IconButton onClick={props.openViewInsuranceModal}>
      <MaterialIcon name="RemoveRedEyeOutlined" />
    </IconButton>
  </Container>
);

const ViewInsurancePage = (props: ViewInsurancePageProps) => (
  <Box display="flex">
    <Box display="flex" flexDirection="column" minWidth={220}>
      <Typography variant="h6" mb={2}>
        {props.t(".insuranceName")}
      </Typography>

      <Typography variant="h6" mb={2}>
        {props.t(".insuranceType")}
      </Typography>

      <Typography variant="h6" mb={2}>
        {props.t(".startDate")}
      </Typography>

      <Typography variant="h6" mb={2}>
        {props.t(".endDate")}
      </Typography>

      <Typography variant="h6" mb={2}>
        {props.t(".premium")}
      </Typography>

      <Typography variant="h6" mb={2}>
        {props.t(".remark")}
      </Typography>

      <Typography variant="h6" mb={2}>
        {props.t(".description")}
      </Typography>
    </Box>

    <Box display="flex" flexDirection="column">
      <Typography variant="body1" mb={2}>
        {props.insurancePlanDetail?.name || "-"}
      </Typography>

      <Typography variant="body1" mb={2}>
        {props.insurancePlanDetail?.insuranceType || "-"}
      </Typography>

      <Typography variant="body1" mb={2}>
        {props.insurancePlanDetail?.protectionStartDate || "-"}
      </Typography>

      <Typography variant="body1" mb={2}>
        {props.insurancePlanDetail?.protectionEndDate || "-"}
      </Typography>

      <Typography variant="body1" mb={2}>
        {props.insurancePlanDetail?.premium || "-"}
      </Typography>

      <Typography variant="body1" mb={2}>
        {props.insurancePlanDetail?.remark || "-"}
      </Typography>

      <CustomHtmlContainer mb={2}>
        <Typography className="CustomHtml" variant="body1" mb={2}>
          {props.insurancePlanDetail?.description ? (
            <div
              dangerouslySetInnerHTML={{
                __html: props.insurancePlanDetail?.description,
              }}
            />
          ) : (
            "-"
          )}
        </Typography>
      </CustomHtmlContainer>
    </Box>
  </Box>
);

export const API = {
  FETCH_INSURANCE_PLAN: gql`
    query FETCH_INSURANCE_PLAN($id: String!) {
      insurancePlan(id: $id) {
        nameTh
        nameEn
        premium
        remarkTh
        remarkEn
        description
        protectionStartDate
        protectionEndDate
        description
        insuranceType {
          nameTh
          nameEn
        }
      }
    }
  `,
};

const enhancer = compose(
  withTranslation({ prefix: "pages.main.insurance.InsurancePackage" }),
  withHooks((props: any, hooks: any) => {
    const { useMemo, useQuery, useDataTranslation } = hooks;
    const { t, insurancePlanId } = props;

    const { data: insurancePlanRes } = useQuery(API.FETCH_INSURANCE_PLAN, {
      variables: { id: insurancePlanId },
      fetchPolicy: "network-only",
      skip: isEmpty(insurancePlanId),
    });

    const insurancePlanTranslated = useDataTranslation(insurancePlanRes);

    const insurancePlan = useMemo(
      () => insurancePlanTranslated?.insurancePlan,
      [insurancePlanTranslated]
    );

    const insurancePlanDetail = useMemo(() => {
      if (insurancePlan) {
        const protectionStartDate = formatDate(
          insurancePlan.protectionStartDate,
          "dd/MM/yyy"
        );
        const protectionEndDate = formatDate(
          insurancePlan.protectionEndDate,
          "dd/MM/yyy"
        );

        return {
          ...insurancePlan,
          insuranceType: insurancePlan.insuranceType.name,
          protectionStartDate: protectionStartDate,
          protectionEndDate: protectionEndDate,
        };
      }
      return;
    }, [insurancePlan]);

    const title = useMemo(() => insurancePlanDetail?.name, [
      insurancePlanDetail,
    ]);

    const openViewInsuranceModal = useCallback(() => {
      if (insurancePlanDetail) {
        Modal.alert({
          title: insurancePlanDetail.name,
          children: (
            <ViewInsurancePage
              t={t}
              insurancePlanDetail={insurancePlanDetail}
            />
          ),
          okButtonLabel: t(".close"),
          onOk: async ({ close }: any) => {
            close();
          },
          okButtonColor: AppColor["Other/Info"],
        });
      }
    }, [t, insurancePlanDetail]);

    return {
      title,
      insurancePlanDetail,
      openViewInsuranceModal,
    };
  })
);

export default enhancer(ViewInsuranceButton);
