import {
  Button,
  DatePicker,
  Field,
  Form,
  Grid,
  Modal,
  Notification,
  Paper,
  TextField,
  Typography,
} from "components";
import Authorize from "components/Authorize";
import { EnumEnrollmentState } from "constants/enums/enrollment-state";
import { PERMISSIONS } from "constants/enums/permissions";
import {
  compose,
  withAuthorize,
  withFormik,
  withHooks,
  withStores,
  withTranslation,
} from "enhancers";
import withPreventLeaveDirtyForm from "enhancers/withPreventLeaveDirtyForm";
import { TFunction } from "i18next";
import { get, isEmpty, isEqual } from "lodash";
import styled from "styled-components";
import { AppColor } from "theme/app-color";
import { Yup, publishedAlert, formatDate, gql } from "utils/helper";
import { handleNavigateEnrollmentStep } from "./enrollmentRoutes";
import { EnumEnrollmentSubState } from "constants/enums/enrollment-sub-state";

const TitleContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface PeriodSettingProps {
  updatedAt: string;
  isFieldDisabled: {
    startDate: boolean;
    endDate: boolean;
    existingAttendeeSelectionPeriodInDays: boolean;
    incomingAttendeeSelectionPeriodInDays: boolean;
  };
  isShowButton: boolean;
  hasEditPermission: boolean;
  hasSubmissionAction: boolean;
  t: TFunction;
}

const PeriodSettingComponent = (props: PeriodSettingProps) => (
  <Paper px={4} py={6}>
    <TitleContainer>
      <Typography variant="h4">
        {props.t(".enrollmentPeriodSetting")}
      </Typography>
      <Typography variant="body1">
        {props.t(".updatedAt", { updatedAt: props.updatedAt })}
      </Typography>
    </TitleContainer>
    <Form style={{ width: "100%", marginTop: "24px" }}>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Field
            fast
            label={props.t(".startDate")}
            fullWidth
            component={DatePicker}
            name="startDate"
            placeholder=""
            required
            disabled={props.isFieldDisabled.startDate}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            fast
            label={props.t(".endDate")}
            fullWidth
            component={DatePicker}
            name="endDate"
            placeholder=""
            required
            disabled={props.isFieldDisabled.endDate}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            fast
            label={props.t(".existingAttendeeSelectionPeriodInDays")}
            fullWidth
            component={TextField}
            name="existingAttendeeSelectionPeriodInDays"
            placeholder=""
            required
            disabled={
              props.isFieldDisabled.existingAttendeeSelectionPeriodInDays
            }
          />
          <Typography variant="caption" color={AppColor["Other/Info"]} mb={4}>
            {props.t(".existingAttendeeSelectionPeriodInDaysInfo")}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Field
            fast
            label={props.t(".incomingAttendeeSelectionPeriodInDays")}
            fullWidth
            component={TextField}
            name="incomingAttendeeSelectionPeriodInDays"
            placeholder=""
            required
            disabled={
              props.isFieldDisabled.incomingAttendeeSelectionPeriodInDays
            }
          />
          <Typography variant="caption" color={AppColor["Other/Info"]} mb={4}>
            {props.t(".incomingAttendeeSelectionPeriodInDaysInfo")}
          </Typography>
        </Grid>
      </Grid>
      <Authorize permissions={[PERMISSIONS.ENROLLMENT_MANAGEMENT_EDIT]}>
        {props.isShowButton && (
          <Button style={{ marginTop: "40px" }} color="primary" type="submit">
            {props.hasSubmissionAction
              ? props.t(".saveAndNext")
              : props.t(".save")}
          </Button>
        )}
      </Authorize>
    </Form>
  </Paper>
);

const API = {
  UPDATE_ENROLLMENT: gql`
    mutation UPDATE_ENROLLMENT(
      $id: String!
      $startDate: String!
      $endDate: String!
      $existingAttendeeSelectionPeriodInDays: Float!
      $incomingAttendeeSelectionPeriodInDays: Float!
      $page: String!
    ) {
      updateEnrollment(
        input: {
          id: $id
          startDate: $startDate
          endDate: $endDate
          existingAttendeeSelectionPeriodInDays: $existingAttendeeSelectionPeriodInDays
          incomingAttendeeSelectionPeriodInDays: $incomingAttendeeSelectionPeriodInDays
          page: $page
        }
      ) {
        id
        startDate
        endDate
        existingAttendeeSelectionPeriodInDays
        incomingAttendeeSelectionPeriodInDays
        subState
      }
    }
  `,
  GET_ENROLLMENT: gql`
    query GET_ENROLLMENT($id: String!) {
      enrollment(id: $id) {
        state
        subState
        startDate
        endDate
        existingAttendeeSelectionPeriodInDays
        incomingAttendeeSelectionPeriodInDays
        updatedAt
      }
    }
  `,
};

const enhancer = compose(
  withStores((stores: any) => ({
    currentUser: stores.appStore.currentUser,
  })),
  withFormik({
    validationSchema: Yup.object().shape({
      existingAttendeeSelectionPeriodInDays: Yup.number()
        .min(1, ".GtZero")
        .typeError(".typeNumber"),
      incomingAttendeeSelectionPeriodInDays: Yup.number()
        .min(1, ".GtZero")
        .typeError(".typeNumber"),
    }),
  }),
  withPreventLeaveDirtyForm(),
  withAuthorize(),
  withTranslation({ prefix: "pages.main.enrollment.PeriodSetting" }),
  withHooks((props: any, hooks: any) => {
    const {
      useHandleSubmit,
      useMutation,
      useParams,
      useEffect,
      useQuery,
      useMemo,
    } = hooks;
    const {
      initialValues,
      setInitialValues,
      hasPermission,
      t,
      disablePreventLeaveDirtyForm,
    } = props;

    const { id } = useParams();

    const { data: enrollmentQry } = useQuery(API.GET_ENROLLMENT, {
      variables: { id },
    });
    const enrollment = useMemo(() => enrollmentQry?.enrollment, [
      enrollmentQry,
    ]);

    const hasSubmissionAction = useMemo(() => {
      return enrollment?.subState === EnumEnrollmentSubState.period_editing;
    }, [enrollment]);

    const [updateEnrollment] = useMutation(API.UPDATE_ENROLLMENT, {
      onCompleted: (data: any) => {
        const enrollment = data.updateEnrollment;
        Notification.success(t(".saveSuccess"));
        if (hasSubmissionAction)
          handleNavigateEnrollmentStep(enrollment.id, enrollment.subState);
      },
      skipSetError: true,
      onError: (errorResponse: any) => {
        const errors = get(errorResponse, "networkError.result.errors");
        if (errors[0].message === "updateUnsuccessfully") {
          publishedAlert();
        }
      },
    });

    const updatedAt = useMemo(() => {
      if (enrollment) {
        return formatDate(enrollment.updatedAt, "dd/MM/yyyy, HH:mm");
      }
      return "";
    }, [enrollment]);

    const hasEditPermission = useMemo(
      () => hasPermission([PERMISSIONS.ENROLLMENT_MANAGEMENT_EDIT]),
      [hasPermission]
    );
    const isFieldDisabled = useMemo(() => {
      let disabled = {
        startDate: true,
        endDate: true,
        existingAttendeeSelectionPeriodInDays: true,
        incomingAttendeeSelectionPeriodInDays: true,
      };
      if (!hasEditPermission) return disabled;
      if (enrollment) {
        switch (enrollment?.state) {
          case EnumEnrollmentState.draft:
          case EnumEnrollmentState.pending: {
            disabled.startDate = false;
            disabled.endDate = false;
            disabled.existingAttendeeSelectionPeriodInDays = false;
            disabled.incomingAttendeeSelectionPeriodInDays = false;
            break;
          }

          case EnumEnrollmentState.published:
          case EnumEnrollmentState.active: {
            const now = new Date();
            const startDate = new Date(enrollment.startDate);
            startDate.setHours(23, 59);
            startDate.setDate(
              startDate.getDate() +
                enrollment.existingAttendeeSelectionPeriodInDays
            );
            if (now <= startDate) {
              disabled.existingAttendeeSelectionPeriodInDays = false;
            }
            break;
          }
          case EnumEnrollmentState.closed:
          default:
            break;
        }
      }
      return disabled;
    }, [hasEditPermission, enrollment]);

    const isShowButton = useMemo(() => {
      let isShow = false;
      Object.keys(isFieldDisabled).forEach((key) => {
        if (!isFieldDisabled[key]) isShow = true;
      });
      return isShow;
    }, [isFieldDisabled]);

    useHandleSubmit(async (values: any) => {
      const {
        startDate,
        endDate,
        existingAttendeeSelectionPeriodInDays,
        incomingAttendeeSelectionPeriodInDays,
      } = values;
      await updateEnrollment({
        variables: {
          id,
          startDate: formatDate(startDate, "yyyy-MM-dd"),
          endDate: formatDate(endDate, "yyyy-MM-dd"),
          existingAttendeeSelectionPeriodInDays: Number(
            existingAttendeeSelectionPeriodInDays
          ),
          incomingAttendeeSelectionPeriodInDays: Number(
            incomingAttendeeSelectionPeriodInDays
          ),
          page: "period_settings",
        },
      });
    }, []);

    useEffect(() => {
      if (isEmpty(initialValues) && enrollment) {
        const {
          startDate,
          endDate,
          existingAttendeeSelectionPeriodInDays,
          incomingAttendeeSelectionPeriodInDays,
        } = enrollment;
        setInitialValues({
          startDate,
          endDate,
          existingAttendeeSelectionPeriodInDays,
          incomingAttendeeSelectionPeriodInDays,
        });
      }
    }, [setInitialValues, initialValues, enrollment]);

    return {
      isShowButton,
      updatedAt,
      isFieldDisabled,
      hasEditPermission,
      hasSubmissionAction,
    };
  })
);

export const PeriodSetting = enhancer(PeriodSettingComponent);
