import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Field,
  FieldArray,
  Form,
  TextField,
  Typography,
} from "components";
import {
  compose,
  withFormik,
  withHooks,
  withStores,
  withTranslation,
} from "enhancers";
import { PageContent } from "layouts";
import { Yup, gql, homePath, notifySuccess, paths } from "utils/helper";

import insuranceStore from "stores/insuranceStore";
import { InsuranceSelector } from "./InsuranceSelector";
import withPreventLeaveDirtyForm from "enhancers/withPreventLeaveDirtyForm";
import { insuranceStepPath } from "../insuranceRoutes";
import { history } from "components/common/BrowserRouter";

const useStyles = makeStyles((theme) => ({
  primaryButton: {
    backgroundColor: "#376FD0",
    borderColor: "#376FD0",
    color: "white",
    "&:hover": {
      backgroundColor: "#376FD0",
      borderColor: "#376FD0",
      color: "white",
    },
  },
  secondaryButton: {
    backgroundColor: "white",
    borderColor: "#376FD0",
    color: "#376FD0",
    "&:hover": {
      backgroundColor: "white",
      borderColor: "#376FD0",
      color: "#376FD0",
    },
  },
}));

export const InsurancePackagesNewPage = (props: any) => (
  <PageContent title={props.title} breadcrumbs={props.breadcrumbs}>
    <Box>
      <Typography variant="h4" mb={6}>
        {props.t(".newPackageHeader")}
      </Typography>
      <Form>
        <Field
          component={TextField}
          name="nameTh"
          label={props.t(".packageNameTh")}
          required
          fullWidth
          mb={6}
        />
        <Field
          component={TextField}
          name="nameEn"
          label={props.t(".packageNameEn")}
          required
          fullWidth
          mb={6}
        />

        {props.insuranceType && props.insuranceType.length > 0 && (
          <FieldArray
            name="insurances"
            component={InsuranceSelector}
            insuranceType={props.insuranceType}
            viewState={props.viewInsuranceState}
            packageIndex={props.packageIndex}
          />
        )}

        <Field
          component={TextField}
          name="remarkTh"
          label={props.t(".remarkTh")}
          // required
          fullWidth
          mb={6}
        />
        <Field
          component={TextField}
          name="remarkEn"
          label={props.t(".remarkEn")}
          // required
          fullWidth
          mb={6}
        />
        <Box display="flex" mt={10}>
          <Button
            width={74}
            p={0}
            mr={2}
            className={props.classes.secondaryButton}
            onClick={props.onBack}
          >
            {props.t(".cancel")}
          </Button>

          <Button
            type="submit"
            width={74}
            p={0}
            className={props.classes.primaryButton}
          >
            {props.t(".save")}
          </Button>
        </Box>
      </Form>
    </Box>
  </PageContent>
);

const API = {
  FETCH_INSURANCE_TYPES: gql`
    query FETCH_INSURANCE_TYPES {
      insuranceTypes {
        id
        symbol
        nameTh
        nameEn
      }
    }
  `,
  FETCH_INSURANCE_DETAIL: gql`
    query FETCH_INSURANCE_DETAIL($id: String!) {
      masterInsuranceDetail(id: $id) {
        id
        year
        state
        companyName
        protectionStartDate
        protectionEndDate
        insurances {
          id
          nameTh
          nameEn
          premium
          remarkTh
          remarkEn
          description
          insuranceTypeId
        }
        insurancePackages {
          id
          nameTh
          nameEn
          remarkTh
          remarkEn
          insurancesInsurancePackages {
            id
            insurancePlanId
            packageId
          }
        }
      }
    }
  `,
};

const enhancer = compose(
  withStores((stores: any) => ({
    packages: stores.insuranceStore.packages,
  })),
  withFormik({
    mapPropsToValues: () => ({
      nameTh: "",
      nameEn: "",
      insurances: ["", "", ""],
      remarkTh: "",
      remarkEn: "",
    }),
    validationSchema: Yup.object().shape({
      nameTh: Yup.string().required("ต้องไม่เว้นว่างเอาไว้"),
      nameEn: Yup.string().required("ต้องไม่เว้นว่างเอาไว้"),
      insurances: Yup.array().of(
        Yup.string().required("ต้องไม่เว้นว่างเอาไว้")
      ),
    }),
  }),
  withTranslation({ prefix: "pages.main.insurance.InsurancePackage" }),
  withPreventLeaveDirtyForm({ onOk: () => history.goBack() }),
  withHooks((props: any, hooks: any) => {
    const {
      useHandleSubmit,
      useParams,
      useQuery,
      useMemo,
      useCallback,
    } = hooks;

    const { t } = props;

    const classes = useStyles();
    const { insuranceId } = useParams();

    const { data: types, loading: insuranceTypeLoading } = useQuery(
      API.FETCH_INSURANCE_TYPES
    );
    const { data: detail, loading: detailLoading } = useQuery(
      API.FETCH_INSURANCE_DETAIL,
      {
        variables: { id: insuranceId },
        onCompleted: (data: any) => {
          const response = data.masterInsuranceDetail;
          insuranceStore.initialMasterInsurance(response);
        },
        fetchPolicy: "network-only",
      }
    );

    const insuranceType = useMemo(() => types?.insuranceTypes || [], [
      types?.insuranceTypes,
    ]);

    const title = t(".add");

    useHandleSubmit(
      async (values: any) => {
        insuranceStore.saveInsurancePackageTemp(values);
        onBack();
        notifySuccess(t(".success"));
      },
      [insuranceId]
    );

    const onBack = useCallback(
      () => insuranceStepPath.insurancePackage(insuranceId).push(),
      [insuranceId]
    );

    const isLoading = useMemo(() => {
      return detailLoading || insuranceTypeLoading;
    }, [detailLoading, insuranceTypeLoading]);

    const config = useMemo(() => {
      if (isLoading) return;
      const year = detail?.masterInsuranceDetail.year;
      return {
        year,
      };
    }, [isLoading, detail]);

    const breadcrumbs = useMemo(() => {
      return [
        {
          path: homePath(),
          label: t(".breadcrumbs.home"),
        },
        {
          path: paths.insurancePath(),
          label: t(".breadcrumbs.insurance"),
        },
        {
          path: insuranceStepPath.insurancePackage(insuranceId).toString(),
          label: t(".breadcrumbs.insuranceDetail", {
            year: config?.year,
          }),
        },
        { path: null, label: title },
      ];
    }, [t, insuranceId, config, title]);

    return {
      classes,
      onBack,
      title,
      breadcrumbs,
      viewInsuranceState: "new",
      t,
      insuranceType,
    };
  })
);

export default enhancer(InsurancePackagesNewPage);
