import { compose, withHooks, withAuthorize } from "enhancers";

const Authorize = (props: any) => (
  <>{props.authorized ? props.children : null}</>
);

const enhancer = compose(
  withAuthorize(),
  withHooks((props: any, hooks: any) => {
    const { hasPermission, permissions = [], children } = props;
    const { useMemo } = hooks;

    const authorized = useMemo(() => {
      return hasPermission(permissions);
    }, [hasPermission, permissions]);

    return { authorized, children };
  })
);

export default enhancer(Authorize);
