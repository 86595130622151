import compose from "enhancers/compose";
import { withFormik } from "formik";
import { isEmpty } from "lodash";
import { withStateHandlers } from "recompose";

const withStateFormik = (config) =>
  compose(
    withStateHandlers(
      {},
      {
        setPropsToFormikBag: (formikBagProps) => (props) => ({
          ...formikBagProps,
          ...props,
        }),
        setInitialValues: (props) => (initialValues) => ({
          ...props,
          __initialValues__: initialValues,
        }),
      }
    ),
    withFormik({
      mapPropsToValues: (props) => {
        if (props.__initialValues__) {
          return props.__initialValues__;
        }
        return {};
      },
      enableReinitialize: true,
      validateOnMount: true,
      validateOnChange: true,
      ...config,
      handleSubmit: async (values, formikBag) => {
        const {
          handleSubmit,
          setInitialValues,
          enablePreventLeaveDirtyForm,
          disablePreventLeaveDirtyForm,
          __initialValues__,
        } = formikBag.props;
        if (handleSubmit) {
          if (enablePreventLeaveDirtyForm && disablePreventLeaveDirtyForm) {
            try {
              await disablePreventLeaveDirtyForm();
              await handleSubmit(values);
              if (isEmpty(__initialValues__)) {
                await setInitialValues(values);
                await enablePreventLeaveDirtyForm();
              }
            } catch (error) {
              await enablePreventLeaveDirtyForm();
            }
          } else {
            await handleSubmit(values);
            await setInitialValues(values);
          }
        }
      },
    })
  );

export default withStateFormik;
