import withHooks from "./withHooks";
import withStores from "./withStores";
import compose from "./compose";
import { PERMISSIONS } from "constants/enums/permissions";

const withAuthorize = (options) =>
  compose(
    withStores((stores) => ({
      currentUser: stores.appStore.currentUser,
    })),
    withHooks((props, hooks) => {
      const { currentUser } = props;
      const { useCallback } = hooks;

      const hasPermission = useCallback(
        (permissions) => {
          return permissions.every((perm) =>
            currentUser?.role?.permissions.includes(perm)
          );
        },
        [currentUser]
      );

      return { ...props, hasPermission };
    })
  );

export default withAuthorize;
