import { compose, withHooks } from "enhancers";
import {
  FormControlLabel,
  Typography,
  Checkbox,
  CheckboxProps as MuiCheckBoxProps,
} from "@material-ui/core";

import { ReactComponent as CheckboxOutlineIcon } from "assets/icon/checkbox_outline.svg";
import { ReactComponent as CheckboxOutlineCheckedIcon } from "assets/icon/checkbox_outline_checked.svg";

interface CheckboxProps extends MuiCheckBoxProps {
  label: string;
}

const CheckboxCustoms = ({
  className,
  label,
  checked,
  ...checkboxProps
}: CheckboxProps) => (
  <FormControlLabel
    className={className}
    control={
      <Checkbox
        checked={checked}
        icon={<CheckboxOutlineIcon />}
        checkedIcon={<CheckboxOutlineCheckedIcon />}
        color="primary"
        size="small"
        indeterminate={false}
        style={{ marginLeft: 5 }}
        {...checkboxProps}
      />
    }
    label={
      <Typography variant="caption" style={{ fontSize: 14 }} align="center">
        {label}
      </Typography>
    }
  />
);

const enhancer = compose(
  withHooks((props: any) => {
    return { ...props };
  })
);

export default enhancer(CheckboxCustoms);
