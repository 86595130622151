import { ComponentsProps } from "@material-ui/core/styles/props";

const props: ComponentsProps = {
  MuiButtonBase: {
    disableRipple: true,
  },
  MuiButton: {
    variant: "contained",
    color: "default",
  },
  MuiLink: {
    color: "primary",
    underline: "none",
  },
  MuiCardHeader: {
    titleTypographyProps: { variant: "h6" },
  },
  MuiTextField: {
    variant: "outlined",
    InputLabelProps: {
      shrink: true,
    },
  },
  MuiDialog: {
    maxWidth: "sm",
  },
};

export default props;
