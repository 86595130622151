import {
  Button,
  Form,
  Typography,
  Paper,
  FieldArray,
  Notification,
  Modal,
} from "components";
import {
  compose,
  withFormik,
  withHooks,
  withStores,
  withTranslation,
} from "enhancers";
import { TFunction } from "i18next";
import enrollmentStore from "stores/enrollmentStore";
import styled from "styled-components";
import { Yup, formatDate, gql, publishedAlert } from "utils/helper";
import GroupPanel from "./GroupPanel";
import { useEffect } from "react";
import { get, isEmpty, isEqual } from "lodash";
import { EnumEnrollmentSubState } from "constants/enums/enrollment-sub-state";
import Authorize from "components/Authorize";
import { PERMISSIONS } from "constants/enums/permissions";
import { handleNavigateEnrollmentStep } from "../enrollmentRoutes";
import { EnumEnrollmentState } from "constants/enums/enrollment-state";
import withPreventLeaveDirtyForm from "enhancers/withPreventLeaveDirtyForm";
import { AppColor } from "theme/app-color";

const TitleContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface PeriodSettingProps {
  viewOnly: boolean;
  updatedAt: string;
  year: string;
  startDate: string;
  endDate: string;
  existingAttendeeSelectionPeriodInDays: string;
  incomingAttendeeSelectionPeriodInDays: string;
  attendeeGroups: [any];
  handleChange: Function;
  expandedList: [any];
  isValid: boolean;
  hasSubmissionAction: boolean;
  t: TFunction;
}

const AttendeeGrouping = (props: PeriodSettingProps) => (
  <Paper px={4} py={6}>
    <TitleContainer>
      <Typography variant="h4">{props.t(".title")}</Typography>
    </TitleContainer>

    <Form style={{ width: "100%", marginTop: "24px" }}>
      <FieldArray name="attendeeGroups" component={GroupPanel} />
      {!props.viewOnly && (
        <Authorize permissions={[PERMISSIONS.ENROLLMENT_MANAGEMENT_EDIT]}>
          <Button
            mt={10}
            color="primary"
            type="submit"
            disabled={!props.isValid}
          >
            {props.hasSubmissionAction
              ? props.t(".saveAndNext")
              : props.t(".save")}
          </Button>
        </Authorize>
      )}
    </Form>
  </Paper>
);

const API = {
  UPDATE_ENROLLMENT: gql`
    mutation UPDATE_ENROLLMENT(
      $id: String!
      $startDate: String!
      $endDate: String!
      $existingAttendeeSelectionPeriodInDays: Float!
      $incomingAttendeeSelectionPeriodInDays: Float!
      $page: String!
      $attendeeGroups: [JSON!]
    ) {
      updateEnrollment(
        input: {
          id: $id
          startDate: $startDate
          endDate: $endDate
          existingAttendeeSelectionPeriodInDays: $existingAttendeeSelectionPeriodInDays
          incomingAttendeeSelectionPeriodInDays: $incomingAttendeeSelectionPeriodInDays
          attendeeGroups: $attendeeGroups
          page: $page
        }
      ) {
        id
        startDate
        endDate
        existingAttendeeSelectionPeriodInDays
        incomingAttendeeSelectionPeriodInDays
        subState
        attendeeGroups {
          id
        }
      }
    }
  `,
  FETCH_ATTENDEE_GROUPS: gql`
    query FETCH_ATTENDEE_GROUPS($enrollmentId: String) {
      attendeeGroups(input: { enrollmentId: $enrollmentId }) {
        id
        titleTh
        titleEn
        point
        masterBenefitInsurances {
          id
          type
          masterBenefitInsurancePlans {
            id
            isDefault
            masterInsurancePlan {
              id
              nameTh
              nameEn
              premium
              remarkTh
              remarkEn
              description
              insuranceTypeId
              insuranceType {
                id
                nameTh
                nameEn
              }
            }
          }
          masterBenefitInsurancePackages {
            id
            isDefault
            masterInsurancePackage {
              id
              nameTh
              nameEn
              remarkTh
              remarkEn
              insurancePackagesInsurancePlans {
                id
                insurance {
                  id
                  nameTh
                  nameEn
                  remarkTh
                  remarkEn
                  premium
                  description
                }
              }
            }
          }
        }
      }
    }
  `,
  GET_ENROLLMENT: gql`
    query GET_ENROLLMENT($id: String!) {
      enrollment(id: $id) {
        year
        state
        subState
        startDate
        endDate
        existingAttendeeSelectionPeriodInDays
        incomingAttendeeSelectionPeriodInDays
        updatedAt
      }
    }
  `,
};

const enhancer = compose(
  withStores((stores: any) => ({
    year: stores.enrollmentStore.year,
    startDate: stores.enrollmentStore.startDate,
    endDate: stores.enrollmentStore.endDate,
    existingAttendeeSelectionPeriodInDays:
      stores.enrollmentStore.existingAttendeeSelectionPeriodInDays,
    incomingAttendeeSelectionPeriodInDays:
      stores.enrollmentStore.incomingAttendeeSelectionPeriodInDays,
    attendeeGroups: stores.enrollmentStore.attendeeGroups,
  })),
  withFormik({
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      existingAttendeeSelectionPeriodInDays: Yup.number(),
      incomingAttendeeSelectionPeriodInDays: Yup.number(),
      attendeeGroups: Yup.array()
        .nullable(true)
        .of(
          Yup.object().shape({
            point: Yup.number()
              .nullable(true)
              .required(".required")
              .typeError(".typeNumber")
              .moreThan(0, "ต้องใส่ตัวเลขที่ไม่น้อยกว่า 0 เท่านั้น"),
            type: Yup.string().nullable(true).required(".required"),
          })
        ),
    }),
  }),
  withPreventLeaveDirtyForm(),
  withTranslation({ prefix: "pages.main.enrollment.attendeeGrouping" }),
  withHooks((props: any, hooks: any) => {
    const {
      useHandleSubmit,
      useMutation,
      useParams,
      useQuery,
      useMemo,
      useDataTranslation,
    } = hooks;
    const {
      setInitialValues,
      attendeeGroups: attendeeGroupsFromStore,
      isValid,
      t,
    } = props;

    const { id } = useParams();

    const { data: enrollmentQry } = useQuery(API.GET_ENROLLMENT, {
      variables: { id },
      onCompleted: (res: any) => {
        if (res.enrollment?.state)
          enrollmentStore.setState({ enrollmentState: res.enrollment.state });
      },
    });
    const enrollment = useMemo(() => enrollmentQry?.enrollment, [
      enrollmentQry,
    ]);

    const { data: attendeeGroupsResponse } = useQuery(
      API.FETCH_ATTENDEE_GROUPS,
      {
        variables: { enrollmentId: id },
        fetchPolicy: "network-only",
      }
    );

    const attendeeGroupsTranslated = useDataTranslation(attendeeGroupsResponse);

    const hasSubmissionAction = useMemo(() => {
      return (
        enrollment?.subState === EnumEnrollmentSubState.attendee_groups_editing
      );
    }, [enrollment]);

    const [updateEnrollment] = useMutation(API.UPDATE_ENROLLMENT, {
      onCompleted: (data: any) => {
        Notification.success(t(".saveSuccess"));
        if (hasSubmissionAction)
          handleNavigateEnrollmentStep(id, data?.updateEnrollment?.subState);
      },
      skipSetError: true,
      onError: (errorResponse: any) => {
        const errors = get(errorResponse, "networkError.result.errors");
        if (errors[0].message === "updateUnsuccessfully") {
          publishedAlert();
        } else {
          const errorsResponse =
            errors[0].extensions.originalError[0].errorResponse;
          Modal.alert({
            title: t(".titleError"),
            children: (
              <>
                <div style={{ marginBottom: "20px", padding: "0px" }}>
                  <Typography
                    variant="Body/16"
                    color={AppColor["Other/Danger"]}
                  >
                    {t(".errorDetail1")}
                  </Typography>
                  <br />
                  <br />
                  <Typography
                    variant="Body/16"
                    color={AppColor["Text/Dark Grey"]}
                  >
                    {t(".errorDetail2")}
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "24px",
                  }}
                >
                  <ul>
                    {errorsResponse.map((item: any) => (
                      <li style={{ color: AppColor["Text/Dark Grey"] }}>
                        <Typography
                          variant="Body/16"
                          color={AppColor["Text/Dark Grey"]}
                        >
                          {item.title}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </div>
              </>
            ),
            onCancel: null,
            okButtonLabel: t(".ok"),
          });
        }
      },
    });

    const attendeeGroups = useMemo(() => {
      if (attendeeGroupsTranslated) {
        return attendeeGroupsTranslated.attendeeGroups.map((group: any) => {
          return {
            ...group,
            title: group.title,
            type: group.masterBenefitInsurances[0]?.type,
          };
        });
      }
      return [];
    }, [attendeeGroupsTranslated]);

    const viewOnly = useMemo(() => {
      let valid = true;
      valid =
        valid && isEqual(enrollment?.state, EnumEnrollmentState.published);
      valid = valid || isEqual(enrollment?.state, EnumEnrollmentState.active);
      valid = valid || isEqual(enrollment?.state, EnumEnrollmentState.closed);
      return valid;
    }, [enrollment]);

    const updatedAt = useMemo(() => {
      if (enrollment) {
        return formatDate(enrollment.updatedAt, "dd/MM/yyyy, HH:mm");
      }
      return "";
    }, [enrollment]);

    useEffect(() => {
      if (!isEmpty(attendeeGroups)) {
        enrollmentStore.setState({
          attendeeGroups,
        });

        setInitialValues({ attendeeGroups });
      }
    }, [attendeeGroups, setInitialValues]);

    useHandleSubmit(
      async (values: any) => {
        if (enrollment) {
          const {
            year,
            startDate,
            endDate,
            existingAttendeeSelectionPeriodInDays,
            incomingAttendeeSelectionPeriodInDays,
          } = enrollment;
          const variables = {
            id,
            year,
            startDate: formatDate(startDate, "yyyy-MM-dd"),
            endDate: formatDate(endDate, "yyyy-MM-dd"),
            existingAttendeeSelectionPeriodInDays: Number(
              existingAttendeeSelectionPeriodInDays
            ),
            incomingAttendeeSelectionPeriodInDays: Number(
              incomingAttendeeSelectionPeriodInDays
            ),
            attendeeGroups: attendeeGroupsFromStore,
            page: "grouping",
          };
          await updateEnrollment({
            variables,
          });
        }
      },
      [enrollment, attendeeGroupsFromStore]
    );

    return {
      viewOnly,
      updatedAt,
      year: enrollment?.year,
      startDate: enrollment?.startDate,
      endDate: enrollment?.endDate,
      existingAttendeeSelectionPeriodInDays:
        enrollment?.existingAttendeeSelectionPeriodInDays,
      incomingAttendeeSelectionPeriodInDays:
        enrollment?.incomingAttendeeSelectionPeriodInDays,
      attendeeGroups,
      isValid,
      hasSubmissionAction,
    };
  })
);

export default enhancer(AttendeeGrouping);
