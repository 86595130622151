export const AppColor = {
  "White / White": "#FFFFFF",
  "Light Blue/Flat Button Background": "#E3F1F9",
  "Gray/Primary Text": "#2B2B2B",
  "Gray/Placeholder": "#606365",
  "Dark Blue/Primary Text": "#003C71",
  "Light Blue/Secondary Text": "#39A0D6",
  "Light Blue/Placeholder": "#8EC9E7",
  "Yellow/Primary Text": "#FFD700",
  "Green/Primary Text": "#2EB375",
  "Green/Button Background": "#E3F4F5",
  "Gray/Flat Button Background": "#B4B4B4",
  "Gray/Background ": "#E6E6E6",
  "Secondary/Pink": "#EE8B9B",
  "Text/Dark": "#B39E9A",
  "Text/Blue": "#0085CA",
  "Text/Dark Blue": "#003C71",
  "Text/Primary Green": "#009CA6",
  "Text/Primary Pink": "#E31C79",
  "Text/Gray": "#CBD0D1",
  "SecondaryText/Gray": "#454748",
  "Text/Black2": "#2B2B2B",
  "Text/Gray Preview": "#606365",
  "Text/Gray Success": "#A2AAAD",
  "Pink/Bg": "#FCE6F0",
  "Gray/Card Border, Line": "#A2AAAD",
  "Gray/Subtle Light": "#AFB1B6",
  "Green/Background": "#E6F6EF",
  "Green/Primary Text2": "#2EB375",
  "Gray/Secondary Text": "#444444",
  "Gray/Line": "#828282",
  "Gray/Placeholder2": "#5D5D5D",
  "Red/Error Text": "#ED3A53",
  "Green/Flat Button Background": "#B5E4CE",
  "Warning Background": "#F9D7B9",
  "Warning Text": "#ED8E3A",
  "Info Background": "#B9D9F9",
  "Info Text": "#3A94ED",
  "Gray/Primary Text2": "#333333",
  "Primary/Blue 1": "#1E293A",
  "Primary/Blue 2": "#233044",
  // "Primary Light / Blue 1": "#E7F4FD",
  // "Text/Black": "#212121",
  // // "Text/Dark Grey": "#767676",
  // "Text/Light Grey": "#A1A1A1",
  "Text/White": "#FFFFFF",
  "Secondary / Blue": "#2296F3",
  "Secondary Light / Blue": "#E7F4FD",
  // "Other/Danger": "#F34336",
  "Other/Warning": "#FF9800",
  // "Other/Info": "#376FD0",
  // "Other/Success": "#4CAF4F",
  "Other Light/Danger": "#FCECE9",
  "Other Light/Warning": "#FFF4E5",
  "Other Light/Info": "#E7F4FD",
  "Other Light/Success": "#EDF7ED",
  // "Background/Dark Grey": "#E0E0E0",
  // "Background/Light Grey": "#F6F9FC",
  "Background / White": "#FFFFFF",
  "SecondaryText / Danger": "#601A15",
  "Background/Light Grey": "#F6F9FC",
  "Other/Info": "#376FD0",
  "Text/Dark Grey": "#767676",
  "Primary Light / Blue 1": "#E7F4FD",
  Gray: "#858585",
  "Text/Black": "#212121",
  "Background/Dark Grey": "#E0E0E0",
  "Text/Light Grey": "#A1A1A1",
  "Other/Danger": "#F34336",
  "Flowkit/Red": "#FC5555",
  "Other/Success": "#4CAF4F",
};
