import { map, find, filter, sortBy } from "lodash";
import bankData from "./banks.json";
import { ReactComponent as baacIcon } from "assets/icon/bank/baac.svg";
import { ReactComponent as bayIcon } from "assets/icon/bank/bay.svg";
import { ReactComponent as bblIcon } from "assets/icon/bank/bbl.svg";
import { ReactComponent as cimbIcon } from "assets/icon/bank/cimb.svg";
import { ReactComponent as citiIcon } from "assets/icon/bank/citi.svg";
import { ReactComponent as dbIcon } from "assets/icon/bank/db.svg";
import { ReactComponent as ghbIcon } from "assets/icon/bank/ghb.svg";
import { ReactComponent as gsbIcon } from "assets/icon/bank/gsb.svg";
import { ReactComponent as hsbcIcon } from "assets/icon/bank/hsbc.svg";
import { ReactComponent as ibankIcon } from "assets/icon/bank/ibank.svg";
import { ReactComponent as icbcIcon } from "assets/icon/bank/icbc.svg";
import { ReactComponent as kbankIcon } from "assets/icon/bank/kbank.svg";
import { ReactComponent as kkIcon } from "assets/icon/bank/kk.svg";
import { ReactComponent as ktbIcon } from "assets/icon/bank/ktb.svg";
import { ReactComponent as lhbIcon } from "assets/icon/bank/lhb.svg";
import { ReactComponent as mbIcon } from "assets/icon/bank/mb.svg";
import { ReactComponent as scIcon } from "assets/icon/bank/sc.svg";
import { ReactComponent as scbIcon } from "assets/icon/bank/scb.svg";
import { ReactComponent as smbcIcon } from "assets/icon/bank/smbc.svg";
import { ReactComponent as tcrbIcon } from "assets/icon/bank/tcrb.svg";
import { ReactComponent as tiscoIcon } from "assets/icon/bank/tisco.svg";
import { ReactComponent as ttbIcon } from "assets/icon/bank/ttb.svg";
import { ReactComponent as uobIcon } from "assets/icon/bank/uob.svg";

const Icons = {
  baac: baacIcon,
  bay: bayIcon,
  bbl: bblIcon,
  cimb: cimbIcon,
  citi: citiIcon,
  db: dbIcon,
  ghb: ghbIcon,
  gsb: gsbIcon,
  hsbc: hsbcIcon,
  ibank: ibankIcon,
  icbc: icbcIcon,
  kbank: kbankIcon,
  kk: kkIcon,
  ktb: ktbIcon,
  lhb: lhbIcon,
  mb: mbIcon,
  sc: scIcon,
  scb: scbIcon,
  smbc: smbcIcon,
  tcrb: tcrbIcon,
  tisco: tiscoIcon,
  ttb: ttbIcon,
  uob: uobIcon,
};

export const getBankNameIcon = (name: string) => {
  const Icon = Icons[name as keyof typeof Icons];
  return Icon ? (
    <Icon height={32} width={32} style={{ borderRadius: "50%" }} />
  ) : null;
};

export const bankNameOptions = sortBy(
  filter(
    map(bankData["th"], (value, key) => ({
      label: value.th_name,
      value: key,
      icon: getBankNameIcon(key),
      code: value.code,
    })),
    (item) => !!item.label
  ),
  ["code"]
);

export const getBankNameLabel = (name: string) => {
  const label = find(bankNameOptions, { value: name })?.label;
  return label;
};
