import React from "react";
import { compose, withFormik, withHooks, defaultProps } from "enhancers";
import {
  Box,
  Grid,
  Field,
  TextField,
  Select,
  Typography,
  Hidden,
} from "components";
import { bankNameOptions, getBankNameIcon, getBankNameLabel } from "./helper";

const BankAccount = (props: any) => (
  <Box {...props}>
    <Typography variant="h4" mb={6}>
      {props.title}
    </Typography>
    <Grid container spacing={6}>
      <Grid item xs={6}>
        <Field
          component={TextField}
          name="bankAccountName"
          type="text"
          label="ชื่อบัญชี"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextField}
          name="bankAccountNumber"
          type="text"
          label="เลขบัญชี"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={Select}
          name="bankName"
          type="text"
          label="ธนาคาร"
          fullWidth
          options={props.bankNameOptions}
          renderOption={props.renderBankNameOption}
        />
      </Grid>
      <Grid item xs={6}>
        <Hidden when={props.noBranch}>
          <Field
            component={TextField}
            name="branchName"
            type="text"
            label="สาขา"
            fullWidth
          />
        </Hidden>
      </Grid>
    </Grid>
  </Box>
);

const enhancer = compose(
  defaultProps({
    bankNameOptions,
  }),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props: any) => ({
      bankAccountName: null,
      bankAccountNumber: null,
      bankName: null,
      branchName: null,
      ...props.field.value,
    }),
  }),
  withHooks((props: any, hooks: any) => {
    const { useCallback, useEffect } = hooks;
    const {
      form,
      field,
      values,
      setFieldValue,
      setValues,
      type,
      ...rest
    } = props;

    const outerSetFieldValue = form.setFieldValue;
    const outerFieldName = field.name;
    const onChange = useCallback(
      (address: string) => {
        outerSetFieldValue(outerFieldName, address);
      },
      [outerSetFieldValue, outerFieldName]
    );

    useEffect(() => {
      onChange(values);
    }, [onChange, values]);

    const renderBankNameOption = useCallback((option: any) => {
      return (
        <>
          <Box mr={2} display="flex" alignItems="center">
            {getBankNameIcon(option)}
          </Box>
          {getBankNameLabel(option)}
        </>
      );
    }, []);

    return {
      ...rest,
      renderBankNameOption,
    };
  })
);

export default enhancer(BankAccount);
