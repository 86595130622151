import { setRefreshToken, setToken } from "api";
import {
  Alert,
  Box,
  Button,
  Field,
  Form,
  TextField,
  Typography,
} from "components";
import { MaterialIcon } from "components/common/MaterialIcon";
import { compose, withFormik, withHooks, withTranslation } from "enhancers";
import { TFunction } from "i18next";
import { MAIN_MENU } from "routes/menu";
import appStore from "stores/appStore";

import styled from "styled-components";
import { AppColor } from "theme/app-color";
import { Yup, gql, homePath, paths } from "utils/helper";

const OtpContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
`;

const RefContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  margin: 24px 0px;
`;

const TextButton = styled(Typography)`
  cursor: pointer;
  color: ${AppColor["Other/Info"]};
`;

const ChangeEmailContainer = styled(Typography)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface VerifyOtpComponentProps {
  handleClickChangeEmail: () => void;
  refCode: string;
  currentEmail: string;
  timeLeft: number;
  canRetry: boolean;
  otpExpireInMin: number;
  handleClickResendRequestOtp: () => void;
  t: TFunction;
}

const VerifyOtpComponent = (props: VerifyOtpComponentProps) => (
  <Box
    width={520}
    bgcolor={AppColor["White / White"]}
    borderRadius="8px"
    marginTop="48px"
  >
    <OtpContainer>
      <div style={{ marginBottom: 24 }}>
        <Typography variant="h4">{props.t(".title")}</Typography>
      </div>
      <Alert
        icon={<MaterialIcon name="Error" />}
        color="info"
        style={{ marginBottom: "24px" }}
      >
        {props.t(".otpInfo", {
          currentEmail: props.currentEmail,
          otpExpireInMin: props.otpExpireInMin,
        })}
      </Alert>

      <Form style={{ width: "100%" }}>
        <Field
          fast
          component={TextField}
          name="otp"
          label={props.t(".otp")}
          fullWidth
        />
        <RefContainer>
          <Typography variant="body1">
            {props.t(".refCode", { refCode: props.refCode })}
          </Typography>
          <div style={{ display: "flex" }}>
            <Typography variant="body1">{props.t(".notReceiveOtp")}</Typography>
            &nbsp;
            {props.canRetry ? (
              <TextButton
                variant="body1"
                onClick={props.handleClickResendRequestOtp}
              >
                {props.t(".retryOtp")}
              </TextButton>
            ) : (
              <Typography variant="body1">
                {props.t(".canRetryIn", {
                  timeLeft: props.timeLeft,
                })}
              </Typography>
            )}
          </div>
        </RefContainer>
        <div style={{ marginTop: 40, marginBottom: 16, width: "100%" }}>
          <Button type="submit" width="100%">
            {props.t(".login")}
          </Button>
        </div>
        <ChangeEmailContainer>
          <TextButton variant="body1" onClick={props.handleClickChangeEmail}>
            {props.t(".changeEmail")}
          </TextButton>
        </ChangeEmailContainer>
      </Form>
    </OtpContainer>
  </Box>
);

const API = {
  VERIFY_BACKOFFICE_USER_VIA_OTP: gql`
    mutation VERIFY_BACKOFFICE_USER_VIA_OTP(
      $otp: String!
      $refCode: String!
      $email: String!
      $token: String!
    ) {
      verifyBackofficeUserViaOtp(
        input: { otp: $otp, refCode: $refCode, email: $email, token: $token }
      ) {
        currentUser {
          id
          email
          authenticationToken
          role {
            permissions
          }
        }
        accessToken
        refreshToken
      }
    }
  `,
};

const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({
      otp: "",
    }),
    validationSchema: Yup.object().shape({
      otp: Yup.number().typeError("รูปแบบ otp ไม่ถูกต้อง"),
    }),
  }),
  withTranslation({ prefix: "pages.main.authentication.VerifyOtp" }),
  withHooks((props: any, hooks: any) => {
    const {
      useHandleSubmit,
      useMemo,
      useEffect,
      useState,
      useCallback,
      useMutation,
    } = hooks;
    const {
      setFieldValue,
      onClickChangeEmail,
      onClickResendRequestOtp,
      refCode,
      currentEmail,
      otpRetryTimeInSec,
      otpExpireInSec,
      token,
    } = props;

    const [verifyOtp] = useMutation(API.VERIFY_BACKOFFICE_USER_VIA_OTP, {
      onCompleted: (data: any) => {
        const {
          accessToken,
          refreshToken,
          currentUser,
        } = data.verifyBackofficeUserViaOtp;
        appStore.setCurrentUser(data.verifyBackofficeUserViaOtp);
        setToken(accessToken);
        setRefreshToken(refreshToken);
        window.location.href = homePath(currentUser.role.permissions);
      },
    });

    const [canRetry, setCanRetry] = useState(false);
    const [timeLeft, setTimeLeft] = useState({
      seconds: otpRetryTimeInSec,
    });

    const otpExpireInMin = useMemo(() => {
      return otpExpireInSec / 60;
    }, [otpExpireInSec]);

    const handleClickChangeEmail = useCallback(() => {
      onClickChangeEmail();
    }, [onClickChangeEmail]);

    const handleClickResendRequestOtp = useCallback(async () => {
      setTimeLeft({
        seconds: otpRetryTimeInSec,
      });
      setCanRetry(false);
      setFieldValue("otp", "");
      await onClickResendRequestOtp(currentEmail);
    }, [
      otpRetryTimeInSec,
      onClickResendRequestOtp,
      currentEmail,
      setFieldValue,
    ]);

    useHandleSubmit(async (values: { otp: string }) => {
      await verifyOtp({
        variables: {
          otp: values.otp.trim(),
          refCode,
          email: currentEmail,
          token,
        },
      });
    });

    useEffect(() => {
      if (!timeLeft.seconds) {
        setCanRetry(true);
        return;
      }
      const intervalId = setInterval(() => {
        if (timeLeft.seconds > 0) {
          setTimeLeft((prevTime: { seconds: number }) => ({
            seconds: prevTime.seconds - 1,
          }));
        }
      }, 1000);
      return () => clearInterval(intervalId);
    }, [timeLeft]);

    return {
      handleClickChangeEmail,
      refCode,
      currentEmail,
      timeLeft: timeLeft.seconds,
      canRetry,
      otpExpireInMin,
      handleClickResendRequestOtp,
    };
  })
);

export const VerifyOtp = enhancer(VerifyOtpComponent);
