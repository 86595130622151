import React, { useMemo } from "react";
import { compose, withHooks } from "enhancers";
import classnames from "classnames";
import { IconProps } from "@material-ui/core";

const IconComponent = (props: IconProps) => (
  <i className={props.className} style={props.style}></i>
);

const enhancer = compose(
  withHooks((props: any) => {
    const { className, name, spin, style } = props;

    const customClassName = useMemo(() => {
      return classnames("fa", {
        [`fa-${name}`]: !!name,
        "fa-spin": spin,
        [className]: className,
      });
    }, [className, name, spin]);

    return {
      className: customClassName,
      style,
    };
  })
);

const Icon = enhancer(IconComponent);

export default Icon;
