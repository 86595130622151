import { useMemo, useCallback } from "react";
import { Route } from "react-router-dom";
import { Path } from "routes/paths";
import { withHooks } from "enhancers";

const enhancer = withHooks((props: any) => {
  const { path, layout: Layout, component: Component, ...rest } = props;

  const customPath = useMemo(() => {
    if (props.path instanceof Path) {
      return props.path.toString();
    } else {
      return props.path;
    }
  }, [props.path]);
  const render = useCallback(
    (routeProps) =>
      Layout ? (
        <Layout>
          <Component {...routeProps} />
        </Layout>
      ) : (
        <Component {...routeProps} />
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [Layout, Component]
  );

  return {
    ...rest,
    path: customPath,
    render,
  };
});

export default enhancer(Route);
