import { LinearProgress } from "@material-ui/core";

import { withHooks } from "enhancers";
import { withStyles } from "@material-ui/core";
import { Box, Typography } from "components";
import { formatNumber } from "utils/helper";

const BorderLinearProgress = withStyles({
  root: {
    height: 24,
    borderRadius: 6,
    backgroundColor: "#E0E0E0",
    "& .MuiLinearProgress-barColorPrimary": { backgroundColor: "#5483D4" },
  },
})(LinearProgress);

interface ProgressProp {
  successValue: number;
  remainingValue: number;
  value: number;
  successPercentage: number;
  remainingPercentage: number;
  firstTextCaption: string;
  secondTextCaption: string;
}

const ProgressBar = (props: ProgressProp) => (
  <Box>
    <Box display="flex" justifyContent="space-between">
      <Typography variant="h4">
        {formatNumber(props.successValue ?? 0)}
      </Typography>
      <Typography variant="h4">
        {formatNumber(props.remainingValue ?? 0)}
      </Typography>
    </Box>
    <Box style={{ position: "relative" }} my={2}>
      <BorderLinearProgress variant="determinate" value={props.value} />
      <Typography
        color="Text/White"
        style={{
          position: "absolute",
          top: 3,
          left: "30px",
          transform: "translateX(-50%)",
        }}
      >
        {`${props.successPercentage}%`}
      </Typography>
      <Typography
        color="Text/Black"
        style={{
          position: "absolute",
          color: "#212121",
          top: 3,
          right: "0px",
          transform: "translateX(-50%)",
        }}
      >
        {`${props.remainingPercentage}%`}
      </Typography>
    </Box>
    <Box display="flex" justifyContent="space-between">
      <Typography variant="caption" color="Text/Black">
        {props.firstTextCaption}
      </Typography>
      <Typography variant="caption" color="Text/Black">
        {props.secondTextCaption}
      </Typography>
    </Box>
  </Box>
);

const enhancer = withHooks((props: any) => {
  return { ...props };
});

export default enhancer(ProgressBar);
