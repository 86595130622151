import { findKey } from "lodash";
import { isClient } from "common/helper";
import { cleanEnv, str } from "envalid";

console.log("process.env", process.env);

export const envConfig = cleanEnv(process.env, {
  REACT_APP_ENV: str({ default: "uat" }),
  REACT_APP_VERSION: str({ default: "0.0.0.0" }),
  REACT_APP_DEFAULT_LANG: str({ default: "th" }),
  NODE_ENV: str({ default: "uat" }),
});

const config = {
  production: {
    HOST: "https://flex.backoffice.uat.zennite.co",
    API_ENDPOINT:
      "https://flex-api.uat.zennite.co/graphql",
  },
  uat: {
    HOST: "https://flex.backoffice.uat.zennite.co",
    API_ENDPOINT:
      "https://flex-api.uat.zennite.co/graphql",
  },
  staging: {
    HOST: "https://dev.backoffice.flex.legaldrive.moveplus.dynu.net",
    API_ENDPOINT:
      "https://dev.backoffice.flex.legaldrive.moveplus.dynu.net/graphql",
  },
  development: {
    HOST: "http://localhost:20902",
    API_ENDPOINT: "http://localhost:20901/graphql",
  },
};

export const getEnvByHost = () =>
  isClient ? findKey(config, { HOST: window.location.origin }) : null;

export const env = envConfig.REACT_APP_ENV || getEnvByHost() || "uat";

const appEnvConfig = {
  production: {
    HOST: "https://flex.uat.zennite.co",
    API_ENDPOINT: "https://flex-api.uat.zennite.co/graphql",
  },
  uat: {
    HOST: "https://flex.uat.zennite.co",
    API_ENDPOINT: "https://flex-api.uat.zennite.co/graphql",
  },
  staging: {
    HOST: "https://dev.app.flex.legaldrive.moveplus.dynu.net",
    API_ENDPOINT: "https://dev.app.flex.legaldrive.moveplus.dynu.net/graphql",
  },
  e2eStaging: {
    HOST: "https://e2e-dev.app.flex.legaldrive.moveplus.dynu.net",
    API_ENDPOINT:
      "https://e2e-dev.app.flex.legaldrive.moveplus.dynu.net/graphql",
  },
  development: {
    HOST: "http://localhost:20903",
    API_ENDPOINT: "http://localhost:20901/graphql",
  },
};

export const appEnv = appEnvConfig[env];

export default config[env];
