import { EnumRowState } from "constants/enums/row-state";
import { IInsuranceDetail } from "constants/interfaces/insuranceDetail";
import { InsurancePackageType } from "constants/interfaces/insurancePackage";
import { IMasterInsurance } from "constants/interfaces/masterInsurance";
import { isEmpty } from "lodash";
import { BaseStore } from "./index";

class InsuranceStore extends BaseStore {
  constructor() {
    super("insuranceStore", {
      insurances: null,
      initInsurances: [],
      companyName: null,
      protectionStartDate: null,
      protectionEndDate: null,
      initMasterInsuranceDetail: {
        companyName: null,
        protectionStartDate: null,
        protectionEndDate: null,
      },
      cleared: true,
      packages: [],
      initialPackages: [],
    });
  }
  getAmountOfPackageInsurance() {
    var numOfinitInsurances = this.state.initInsurances.length;
    return numOfinitInsurances;
  }

  saveInsuranceTemp(id: string, data: IInsuranceDetail) {
    const prevData = this.state["insurances"][id];
    let newData: IInsuranceDetail = {
      ...data,
    };

    if (JSON.stringify(prevData) !== JSON.stringify(data)) {
      newData.rowState = EnumRowState.MODIFIED;
    }

    const newInsurances = {
      ...this.state["insurances"],
      [id]: newData,
    };
    this.setState({
      ...this.state,
      insurances: newInsurances,
      cleared: false,
    });
  }

  deleteInsurance(id: string) {
    const insurances = this.state["insurances"];
    let newInsurances: any = null;

    const data = { ...insurances[id], rowState: EnumRowState.DELETED };
    newInsurances = {
      ...insurances,
      [id]: data,
    };
    this.setState({
      ...this.state,
      insurances: newInsurances,
      cleared: false,
    });
  }

  private initialInsurances(insurances: IInsuranceDetail[]) {
    let insuranceDataDic = {};
    insurances.forEach((insurance) => {
      insuranceDataDic = { ...insuranceDataDic, [insurance.id]: insurance };
    });

    this.setState({
      ...this.state,
      insurances: insuranceDataDic,
      initInsurances: insurances.map((insurance) => insurance.id),
      cleared: false,
    });
  }

  initialInsurancePackages(insurancePackages: InsurancePackageType[]) {
    const newInsurancePackages: any[] = [];

    insurancePackages.forEach((packageItem) => {
      const insurances: string[] = [];
      packageItem.insurancePackagesInsurancePlans.forEach((pack: any) =>
        insurances.push(pack.insurancePlanId)
      );

      newInsurancePackages.push({
        id: packageItem.id,
        nameTh: packageItem.nameTh,
        nameEn: packageItem.nameEn,
        insurances,
        remarkTh: packageItem.remarkTh,
        remarkEn: packageItem.remarkEn,
      });
    });

    this.setState({
      ...this.state,
      packages: newInsurancePackages,
      initialPackages: newInsurancePackages,
      cleared: false,
    });
  }

  initialMasterInsurance(masterInsurance: IMasterInsurance) {
    if (isEmpty(this.state["insurances"])) {
      this.setState({
        initMasterInsuranceDetail: {
          companyName: masterInsurance.companyName,
          protectionStartDate: masterInsurance.protectionStartDate,
          protectionEndDate: masterInsurance.protectionEndDate,
        },

        companyName: masterInsurance.companyName,
        protectionStartDate: masterInsurance.protectionStartDate,
        protectionEndDate: masterInsurance.protectionEndDate,

        cleared: false,
      });
      const setRowState = masterInsurance.insurances.map((insurance) => ({
        ...insurance,
        rowState: EnumRowState.UNCHANGED,
      }));
      this.initialInsurances(setRowState);
    }
  }

  clearData() {
    this.setState({
      insurances: null,
      initInsurances: [],
      initMasterInsuranceDetail: {
        companyName: null,
        protectionStartDate: null,
        protectionEndDate: null,
      },
      companyName: null,
      protectionStartDate: null,
      protectionEndDate: null,
      packages: [],
      initialPackages: [],
      cleared: true,
    });
  }

  saveInsurancePackageTemp = (data: any) => {
    const newPackages = [...this.state["packages"]];
    newPackages.push(data);

    this.setState({
      ...this.state,
      packages: newPackages,
    });
  };

  updateInsurancePackageTemp(index: number, data: any) {
    const newPackages = [...this.state["packages"]];
    newPackages[index] = data;

    this.setState({
      ...this.state,
      packages: newPackages,
    });
  }

  deleteInsurancePackageTemp(index: number) {
    const newPackages = [...this.state["packages"]];
    newPackages.splice(index, 1);

    this.setState({
      ...this.state,
      packages: newPackages,
    });
  }

  checkIsInPackage(id: string) {
    let allInsuranceInPackage: any[] = [];
    this.state.packages.forEach((data: any) => {
      allInsuranceInPackage = [...allInsuranceInPackage, ...data.insurances];
    });
    return allInsuranceInPackage.find((data) => data === id);
  }
}

const insuranceStore = new InsuranceStore();

export default insuranceStore;
