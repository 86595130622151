import { useMemo } from "react";
import { Switch } from "react-router-dom";
import { compose, withHooks, withStores } from "enhancers";
import { safe } from "common/helper";
import { isArray } from "lodash";

const enhancer = compose(
  withStores((stores: any) => ({
    currentUser: stores.appStore.currentUser,
  })),
  withHooks((props: any) => {
    const { currentUser } = props;

    const children = useMemo(() => {
      if (props.children && isArray(props.children)) {
        return props.children.filter((child: any, index: number) => {
          const permittedRoles = safe(() => child.props.permittedRoles, null);

          if (permittedRoles && currentUser) {
            return permittedRoles.every((perm: any) =>
              currentUser?.role?.permissions.includes(perm)
            );
          }

          return true;
        });
      }
    }, [props.children, currentUser]);

    return {
      ...props,
      children,
    };
  })
);

export default enhancer(Switch);
