import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Paper,
  Typography,
} from "components";
import Authorize from "components/Authorize";
import { MaterialIcon } from "components/common/MaterialIcon";
import { PERMISSIONS } from "constants/enums/permissions";
import {
  compose,
  withFormik,
  withHooks,
  withStores,
  withTranslation,
} from "enhancers";
import { isEmpty, isEqual, uniq } from "lodash";
import styled from "styled-components";
import { AppColor } from "theme/app-color";
import { formatDate, gql, paths, publishedAlert } from "utils/helper";
import ViewInsuranceButton from "../insurance/insurancePackages/ViewInsuranceButtonComponent";
import { handleNavigateEnrollmentStep } from "./enrollmentRoutes";
import { EnumEnrollmentState } from "constants/enums/enrollment-state";

const IconButton = styled(Box)`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-self: center;
  width: 16px;
  height: 16px;
  justify-content: center;

  :hover {
    cursor: pointer;
  }

  > svg {
    width: 16px;
    height: 16px;
  }
`;

const InsurancePackageComponent = (props: any) => (
  <>
    <Paper px={4} py={6}>
      <Box>
        <Typography variant="h4" color={AppColor["Text/Black"]} mb={10}>
          {props.t(".title")}
        </Typography>
        {props.insurancePackages.map((packageItem: any, index: number) => (
          <Accordion
            expanded={[packageItem].every((attend: any) =>
              props.expanded.includes(attend)
            )}
            key={packageItem}
            onChange={props.handleChange(packageItem)}
          >
            <AccordionSummary
              expandIcon={
                <MaterialIcon
                  name="ExpandMore"
                  htmlColor={AppColor["Text/Dark Grey"]}
                />
              }
              style={{
                borderBottom: `1px solid ${AppColor["Background/Dark Grey"]}`,
              }}
            >
              <Typography variant="body1" color={AppColor["Text/Black"]}>
                {`Package ${packageItem.name}`}
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Box display="flex" flexDirection="column" p={4} width="100%">
                <Box display="flex" mb={2}>
                  <Box display="flex" flex={1}>
                    <Typography variant="body2" color={AppColor["Text/Black"]}>
                      {props.t(".name")}
                    </Typography>
                  </Box>
                  <Box display="flex" flex={1}>
                    <Typography variant="body1" color={AppColor["Text/Black"]}>
                      {packageItem.name}
                    </Typography>
                  </Box>
                </Box>

                {packageItem.insurances.map((insurance: any, index: number) => (
                  <Box display="flex" mb={2} position="relative">
                    <Box display="flex" flex={1}>
                      <Typography
                        variant="body2"
                        color={AppColor["Text/Black"]}
                      >
                        {props.t(".insuranceName", {
                          insuranceName: insurance.type,
                        })}
                      </Typography>
                    </Box>
                    <Box display="flex" flex={1}>
                      <Typography
                        variant="body1"
                        color={AppColor["Text/Black"]}
                      >
                        {insurance.name}
                      </Typography>
                    </Box>
                    <IconButton alignSelf="center">
                      <ViewInsuranceButton insurancePlanId={insurance.id} />
                    </IconButton>
                  </Box>
                ))}

                <Box display="flex">
                  <Box display="flex" flex={1}>
                    <Typography variant="body2" color={AppColor["Text/Black"]}>
                      {props.t(".remark")}
                    </Typography>
                  </Box>
                  <Box display="flex" flex={1}>
                    <Typography variant="body1" color={AppColor["Text/Black"]}>
                      {packageItem.remark}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
        <Box
          mt="40px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Authorize permissions={[PERMISSIONS.ENROLLMENT_MANAGEMENT_EDIT]}>
              {!props.viewOnly && (
                <Button
                  color="Primary"
                  variant="contained"
                  onClick={props.handleClickNext}
                >
                  {props.t(".next")}
                </Button>
              )}
            </Authorize>
          </Box>
          <Box display="flex" alignItems="center" ml="8px">
            <Typography variant="caption" color={AppColor["Text/Black"]}>
              {props.t(".lastUpdate", {
                updatedAt: props.updatedAt,
              })}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  </>
);

const API = {
  FETCH_INSURANCE_PACKAGES: gql`
    query FETCH_INSURANCE_PACKAGES($id: String!) {
      insurancePackages(id: $id) {
        id
        nameTh
        nameEn
        remarkTh
        remarkEn
        insurancePackagesInsurancePlans {
          id
          insurancePlanId
          packageId
        }
      }
    }
  `,
  FETCH_INSURANCE_TYPES: gql`
    query FETCH_INSURANCE_TYPES {
      insuranceTypes {
        id
        symbol
        nameTh
        nameEn
      }
    }
  `,
  CHANGE_TO_ATTENDEE_GROUPS: gql`
    mutation CHANGE_TO_ATTENDEE_GROUPS($id: String!) {
      changeToAttendeeGroups(id: $id) {
        subState
      }
    }
  `,
  GET_ENROLLMENT: gql`
    query GET_ENROLLMENT($id: String!) {
      enrollment(id: $id) {
        state
        masterInsurance {
          id
          updatedAt
        }
      }
    }
  `,
};

const enhancer = compose(
  withStores((stores: any) => ({
    masterInsuranceId: stores.enrollmentStore.masterInsuranceId,
    insurances: stores.insuranceStore.insurances,
  })),
  withFormik({}),
  withTranslation({
    prefix: "pages.main.enrollment.enrollmentInsurancePackage",
  }),
  withHooks((props: any, hooks: any) => {
    const {
      useQuery,
      useMemo,
      useCallback,
      useState,
      useParams,
      useMutation,
      useDataTranslation,
    } = hooks;
    const { insurances, masterInsuranceId } = props;
    const { id } = useParams();
    const { data: insuranceTypeData = {} } = useQuery(
      API.FETCH_INSURANCE_TYPES,
      {
        fetchPolicy: "network-only",
      }
    );

    const [expanded, setExpanded] = useState([]);

    const { data: insurancePackagesResponse } = useQuery(
      API.FETCH_INSURANCE_PACKAGES,
      {
        variables: { id },
        fetchPolicy: "network-only",
      }
    );

    const insurancePackageTranslated = useDataTranslation(
      insurancePackagesResponse
    );

    const insuranceTranslated = useDataTranslation(insurances);
    const insuranceTypesTranslated = useDataTranslation(insuranceTypeData);

    const [changeToAttendeeGroups] = useMutation(
      API.CHANGE_TO_ATTENDEE_GROUPS,
      {
        onCompleted: (data: any) => {
          const { subState } = data.changeToAttendeeGroups;
          handleNavigateEnrollmentStep(id, subState);
        },
        skipSetError: true,
        onError: () => {
          publishedAlert();
        },
      }
    );

    const { data: enrollmentQry } = useQuery(API.GET_ENROLLMENT, {
      variables: { id },
    });

    const enrollment = useMemo(() => enrollmentQry?.enrollment, [
      enrollmentQry,
    ]);

    const handleChange = useCallback(
      (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(
          isExpanded
            ? uniq([...expanded, panel])
            : expanded.filter((expand: any) => expand !== panel)
        );
      },
      [setExpanded, expanded]
    );

    const viewInsurance = useCallback(
      (insuranceId: String) => () => {
        paths
          .insurancePackageViewInsurancePath(masterInsuranceId, insuranceId, {
            viewState: "enrollment",
          })
          .newTab();
      },
      [masterInsuranceId]
    );

    const packages = useMemo(
      () => insurancePackageTranslated?.insurancePackages,
      [insurancePackageTranslated]
    );

    const insurancePackages = useMemo(() => {
      if (
        isEmpty(packages) ||
        isEmpty(insurances) ||
        isEmpty(insuranceTypesTranslated)
      ) {
        return [];
      }

      const types = insuranceTypesTranslated.insuranceTypes;

      const result = packages.map((packageItem: any) => {
        const insuranceDetail = packageItem.insurancePackagesInsurancePlans.map(
          (item: any) => {
            const insuranceTypeId =
              insurances[item.insurancePlanId]?.insuranceTypeId;

            const typeR = types.find(
              (type: any) => type.id === insuranceTypeId
            );

            return {
              ...insuranceTranslated[item.insurancePlanId],
              type: typeR?.name,
            };
          }
        );

        return {
          ...packageItem,
          insurances: insuranceDetail,
        };
      });

      return result;
    }, [packages, insurances, insuranceTypeData]);

    const handleClickNext = useCallback(() => {
      changeToAttendeeGroups({ variables: { id } });
    }, [changeToAttendeeGroups, id]);

    const viewOnly = useMemo(() => {
      let valid = true;
      valid =
        valid && isEqual(enrollment?.state, EnumEnrollmentState.published);
      valid = valid || isEqual(enrollment?.state, EnumEnrollmentState.active);
      valid = valid || isEqual(enrollment?.state, EnumEnrollmentState.closed);
      return valid;
    }, [enrollment]);

    const updatedAt = useMemo(() => {
      if (enrollment) {
        return formatDate(
          enrollment.masterInsurance.updatedAt,
          "dd/MM/yyyy, HH:mm"
        );
      }
      return "";
    }, [enrollment]);

    return {
      viewOnly,
      insurancePackages,
      handleChange,
      expanded,
      viewInsurance,
      updatedAt,
      handleClickNext,
    };
  })
);

export const EnrollmentInsurancePackage = enhancer(InsurancePackageComponent);
