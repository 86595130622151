export enum EnumEnrollmentSubState {
  "period_editing" = "period_editing",
  "attendees_validating" = "attendees_validating",
  "insurance_plans_validating" = "insurance_plans_validating",
  "insurance_packages_validating" = "insurance_packages_validating",
  "attendee_groups_editing" = "attendee_groups_editing",
  "validating" = "validating",
  "completed" = "completed",
}

export const EnrollmentStateKey = "ENROLLMENT_STATE";
