import React, { useCallback, useEffect, useState } from "react";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { compose, withHooks, withStores } from "enhancers";
import { get } from "lodash";
import appStore from "stores/appStore";

const ExcelGeneratorComponent = ({
  handleSetExporter,
  fileName,
  configs,
  ...rest
}) => {
  return configs.map((config, ind) => (
    <ExcelExport
      fileName={fileName}
      sheetName={config.sheetName}
      columns={config.columns}
      data={config.data}
      ref={handleSetExporter}
    />
  ));
};

const enhancer = compose(
  withStores((stores, props) =>
    props.storeName ? get(stores, props.storeName) : {}
  ),
  withHooks((props) => {
    const {
      fileName,
      columns,
      data,
      triggeredGenerator,
      onGenerated,
      configs = [],
    } = props;
    const [exporters, setExporters] = useState([]);

    const handleExportExcel = useCallback(() => {
      if (exporters.length > 0 && exporters.length === configs.length) {
        const tempMainExporter = exporters[0];
        const mainExporter = {
          ...tempMainExporter,
          props: { ...tempMainExporter.props, fileName },
        };
        const mainOptions = mainExporter.workbookOptions();
        for (const [ind, exporter] of exporters.entries()) {
          if (ind !== 0) {
            const optionsGrid = exporter.workbookOptions();
            mainOptions.sheets[ind] = optionsGrid.sheets[0];
            mainOptions.sheets[ind].title = configs[ind].sheetName;
          }
          mainOptions.sheets[ind].title = configs[ind].sheetName;
        }
        mainExporter.save(mainOptions); // Open to download
      }
    }, [exporters, configs, fileName]);

    const handleSetExporter = useCallback(
      (props) => {
        if (props) {
          const isExisted = exporters.find(
            (exporter) => exporter.props.sheetName === props?.props.sheetName
          );
          if (!isExisted) setExporters([props, ...exporters]);
        }
      },
      [exporters]
    );

    useEffect(() => {
      if (triggeredGenerator) {
        handleExportExcel();
        if (onGenerated) {
          onGenerated();
        }
      }
    }, [handleExportExcel, triggeredGenerator, onGenerated]);

    return { handleSetExporter, configs, fileName, columns, data };
  })
);

const ExcelGenerator = enhancer(ExcelGeneratorComponent);

ExcelGenerator.generate = appStore.generateExcel;

export default ExcelGenerator;
