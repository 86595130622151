import { compose, withHooks, withTranslation } from "enhancers";
import Alert, { AlertProps as MuiAlertProps } from "@material-ui/lab/Alert";
import { map } from "lodash";
import { Typography } from ".";

interface AlertProps extends MuiAlertProps {
  errorMessage: string;
}

const ErrorMessage = ({ errorMessage, ...alertProps }: AlertProps) =>
  errorMessage ? (
    <Alert severity="error" {...alertProps}>
      {errorMessage}
    </Alert>
  ) : null;

const enhancer = compose(
  withTranslation(),
  withHooks((props: any, hooks: any) => {
    const { name, t, ...alertProps } = props;
    const { useMemo, useFormikContext } = hooks;
    const formikBag = useFormikContext();

    const errorMessage = useMemo(() => {
      if (!formikBag || !formikBag.errors[name]) {
        return null;
      }

      const errors = formikBag.errors[name]?.split("\n");
      if (errors.length === 1) {
        return errors[0];
      } else {
        return map(errors, (error) => <Typography>{t(error)}</Typography>);
      }
    }, [formikBag, name, t]);

    return {
      errorMessage,
      ...alertProps,
    };
  })
);

export default enhancer(ErrorMessage);
