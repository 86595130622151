import { compose, withHooks, defaultProps } from "enhancers";
import { useTranslation } from "react-i18next";
import { Cron as ReactJsCron } from "react-js-cron";
import "react-js-cron/dist/styles.css";
import "./index.css";
import { LOCALE } from "./locales";
import { DEFAULT_LOCALE_TH } from "./locales/languages/th";

const enhancer = compose(
  defaultProps({
    customProps: {
      className: "my-project-cron",
      locale: DEFAULT_LOCALE_TH,
      clearButtonProps: { type: "default", danger: false },
    },
  }),
  withHooks((props: any, hooks: any) => {
    const { useCallback, useMemo } = hooks || {};
    const { field, form, disabled, customProps } = props || {};
    const { className } = customProps || {};
    const { value, name } = field || {};
    const { setFieldValue } = form || {};

    const setValue = useCallback(
      (value: string) => {
        setFieldValue(name, value);
      },
      [setFieldValue, name]
    );

    const { i18n } = useTranslation();
    const customClassName = useMemo(() => {
      return `${customProps.className} ${i18n.language} ${className}`;
    }, [i18n.language, customProps.className, className]);

    const locale = useMemo(() => {
      return LOCALE[i18n.language];
    }, [i18n.language]);

    return {
      ...customProps,
      locale,
      value,
      setValue,
      disabled,
      className: customClassName,
    };
  })
);

export default enhancer(ReactJsCron);
