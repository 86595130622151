import paths from "routes/paths";

import { RouteType } from "types/routes";

// Menu Icon
import { ReactComponent as AdminIcon } from "vendor/menu_icon/admin.svg";
import { ReactComponent as Settings } from "vendor/menu_icon/settings.svg";
import { ReactComponent as InsuranceIcon } from "vendor/menu_icon/insurance-icon.svg";
import { ReactComponent as RoleIcon } from "vendor/menu_icon/role-icon.svg";
import { ReactComponent as ReportMenuIcon } from "vendor/menu_icon/export-report.svg";
import { ReactComponent as Enrollment } from "vendor/menu_icon/enrollment.svg";
import { AppColor } from "theme/app-color";

// export const ADMIN_MAIN_MENU: RouteType[] = [
//   {
//     id: "client.routes.menu.enrollment",
//     path: paths.enrollmentPath().toString(),
//     header: "client.routes.menu.menu",
//     icon: <Enrollment />,
//   },
//   {
//     id: "client.routes.menu.report",
//     path: paths.reportPath().toString(),
//     icon: <ReportMenuIcon />,
//   },
//   {
//     id: "client.routes.menu.adminMenu",
//     path: paths.adminsPath().toString(),
//     icon: <AdminIcon />,
//   },
//   {
//     id: "client.routes.menu.roleMenu",
//     path: paths.rolesPath().toString(),
//     icon: <RoleIcon />,
//   },
//   {
//     id: "client.routes.menu.settingMenu",
//     path: paths.settingsPath().toString(),
//     icon: <Settings />,
//   },
//   {
//     id: "client.routes.menu.insurance",
//     path: paths.insurancePath().toString(),
//     icon: <InsuranceIcon fill={AppColor["White / White"]} />,
//   },
// ];

// export const SUPER_ADMIN_MAIN_MENU: RouteType[] = [
//   {
//     id: "client.routes.menu.enrollment",
//     path: paths.enrollmentPath().toString(),
//     header: "client.routes.menu.menu",
//     icon: <Enrollment />,
//   },
//   {
//     id: "client.routes.menu.report",
//     path: paths.reportPath().toString(),
//     icon: <ReportMenuIcon />,
//   },
//   {
//     id: "client.routes.menu.adminMenu",
//     path: paths.adminsPath().toString(),
//     icon: <AdminIcon />,
//   },
//   {
//     id: "client.routes.menu.roleMenu",
//     path: paths.rolesPath().toString(),
//     icon: <RoleIcon />,
//   },
//   {
//     id: "client.routes.menu.settingMenu",
//     path: paths.settingsPath().toString(),
//     icon: <Settings />,
//   },
//   {
//     id: "client.routes.menu.insurance",
//     path: paths.insurancePath().toString(),
//     icon: <InsuranceIcon fill={AppColor["White / White"]} />,
//   },
// ];

export const MAIN_MENU: RouteType[] = [
  {
    id: "client.routes.menu.insurance",
    header: "client.routes.menu.menu",
    path: paths.insurancePath().toString(),
    icon: <InsuranceIcon fill={AppColor["White / White"]} />,
    permissions: ["INSURANCE_MANAGEMENT_READ"],
  },
  {
    id: "client.routes.menu.enrollment",
    path: paths.enrollmentPath().toString(),
    icon: <Enrollment />,
    permissions: ["ENROLLMENT_MANAGEMENT_READ"],
  },
  {
    id: "client.routes.menu.report",
    path: paths.reportPath().toString(),
    icon: <ReportMenuIcon />,
    permissions: ["REPORT_MANAGEMENT_READ"],
  },
  {
    id: "client.routes.menu.roleMenu",
    path: paths.rolesPath().toString(),
    icon: <RoleIcon />,
    permissions: ["PERMISSION_MANAGEMENT_READ"],
  },
  {
    id: "client.routes.menu.adminMenu",
    path: paths.adminsPath().toString(),
    icon: <AdminIcon />,
    permissions: ["ADMIN_MANAGEMENT_READ"],
  },
  {
    id: "client.routes.menu.settingMenu",
    path: paths.settingsPath().toString(),
    icon: <Settings />,
    permissions: ["SETTING_MANAGEMENT_READ"],
  },
];

// export const HR_MAIN_MENU: RouteType[] = [
//   // {
//   //   id: "การจองคิว",
//   //   path: paths.supplierBookingsPath().toString(),
//   //   header: "เมนู",
//   //   icon: <AdminIcon />,
//   // },
// ];

// export default MAIN_MENU;
